import React from 'react'
import waterkloofLogo from '../assets/kloof.jpeg'
import kloofrb from '../assets/kloofRB.png'
import maraisviljoen from '../assets/maraisviljoen.jpeg'
import maraisviljoenRB from '../assets/maraisviljoenRB.png'
import monnaslogo from '../assets/Monnas Logo.jpeg'
import monnaslogoRB from '../assets/MonnasRB.png'
import affiesWapen from '../assets/Affies_wapen.jpg'
import AffiesLogo from '../assets/affies-logo.png'
import AffiesLogoRB from '../assets/AffiesLogoRB.png'
import EGJansenLogo from '../assets/EGJansenLogo.png'
import EGJansenLogoRB from '../assets/EGJansenLogoRB.png'
import MenloLogo from '../assets/menlo.jpeg'
import menloLogoRB from '../assets/menlo-RB.png'
import SquadControlLogo from '../assets/SquadControlWhite.png'
import SquadControlWhiteRB from '../assets/SquadControlWhiteRB.png'
import BenVorster from '../assets/Ben Vorster.jpeg'
import Boland from '../assets/Boland.jpeg'
import BolandRB from '../assets/BolandRB.png'
import Brandwag from '../assets/Brandwag.png'
import Centurion from '../assets/Centurion.jpeg'
import CenturionRB from '../assets/CenturionRB.png'
import CBCBoksburg from '../assets/CBC Boksburg.jpeg'
import CBCBoksburgRB from '../assets/CBC_Boksburg-removebg-preview (1).png'
import Eldoraigne from '../assets/Eldoraigne.jpeg'
import EldoraigneRB from '../assets/EldoraigneRB.png'
import Ermelo from '../assets/Ermelo.png'
import FrikkieMeyer from '../assets/Frikkie Meyer.png'
import FrikkieMeyerRB from '../assets/Frikkie_MeyerRB.png'
import Garsfontein from '../assets/garsfontein.png'
import Grey from '../assets/Grey College.jpeg'
import GreyRB from '../assets/Grey College RB.png'
import Helpmekaar from '../assets/HelpMekaar.png'
import Rustenburg from '../assets/Rustenburg-removebg-preview.png'
import JohnVorster from '../assets/HTS John Vorster.png'
import HtsMiddelburg from '../assets/HTS_Middelburg-removebg-preview (1).png'
import Kempton from '../assets/Kempton Park.png'
import Marlow from '../assets/Marlow Landbou.png'
import MarlowRB from '../assets/Marlow_LandbouRB.png'
import Merensky from '../assets/Merensky.png'
import Nelspruit from '../assets/Nelspruit.png'

import Noordheuwel from '../assets/Noordheuwel.jpeg'
import NoordheuwelRB from '../assets/Noordheuwel-RB.png'
import OosMoot from '../assets/oos moot.png'
import OuteniquaRB from '../assets/Outeniqua-RB.png'
import PaarlBoysHighSchool from "../assets/Paarl Boys' High School.png"
import PaarlGim from '../assets/paarlGim.jpg'
import PaarlGimRB from '../assets/paarlGim-RB.png'
import PHS from '../assets/PHS.png'
import PHSRB from '../assets/PHS-RB.png'
import PretoriaBoysHigh from '../assets/PBH-RB.png'
import Dinamika from '../assets/DinamikaLogo.png'

//import PietRetief from '../assets/Piet Retief.png'
import PietRetiefRB from '../assets/Piet_Retief-removebg-preview.png'

import Transvalia from '../assets/Transvalia.png'
import TransvaliaRB from '../assets/Transvalia-RB.png'
import Wonderboom from '../assets/Wonderboom.png'
import Zwartkop from '../assets/Zwartkop.png'

import GarsfonteinLogo from '../assets/garsfonteinLogo.png'
import Framesby from '../assets/framesbyLogo.png'
import Selborne from '../assets/selbourneLogo.png'
import Hugenote from '../assets/Hugenote (1).png'
import HugenoteRB from '../assets/Hugenote-removebg-preview (1).png'
import BenoniHigh from '../assets/Benoni High (1).jpeg'
import BenoniHighRB from '../assets/Benoni_High-removebg-preview (1).png'


//NEW SCHOOLS
import OakdaleLogo from '../assets/Oakdale_Logo-removebg-preview.png'

// KESLogo;
import KESLogo from '../assets/KES_Logo-removebg-preview.png'
// JeppeLogo;
import JeppeLogo from '../assets/Jeppe_Logo-removebg-preview.png'
// DurbanHighLogo;
import DurbanHighLogo from '../assets/DHS_Logo-removebg-preview.png'
// RondeboschLogo;
import RondeboschLogo from '../assets/Rondebosch_Logo-removebg-preview (1).png'
// StellenbergLogo;
import StellenbergLogo from '../assets/Stellenberg_Logo.png'
// WestvilleLogo;
import WestvilleLogo from '../assets/Westville_Logo-removebg-preview (1).png'
// MaritzburgCollegeLogo;
import MaritzburgCollegeLogo from '../assets/Maritzburg_Logo-removebg-preview (1).png'
// GreyHighLogo;
import GreyHighLogo from '../assets/Grey_PE_Logo-removebg-preview (1).png'
// WynbergBoysLogo;
import WynbergBoysLogo from '../assets/Wynberg_Logo-removebg-preview (1).png'
// MichaelhouseLogo;
import MichaelhouseLogo from '../assets/Michealhouse_Logo-removebg-preview (1).png'
// SACSLogo;
import SACSLogo from '../assets/SACS_Logo-removebg-preview (1).png'
// HiltonCollegeLogo;
import HiltonCollegeLogo from '../assets/Hilton_Logo-removebg-preview (1).png'
// QueensCollegeLogo;
import QueensCollegeLogo from '../assets/Queens_Logo-removebg-preview (1).png'
// DiamantveldLogo;
import DiamantveldLogo from '../assets/Diamantveld_Logo-removebg-preview (1).png'
// NicoMalanLogo;
import NicoMalanLogo from '../assets/Nico_Malan_Logo-removebg-preview (1).png'
// StJohnsLogo;
import StJohnsLogo from '../assets/St_Johns_Logo-removebg-preview (1).png'
// KingswoodLogo;
import KingswoodLogo from '../assets/Kingwood_Logo-removebg-preview (1).png'
// StCharlesLogo;
import StCharlesLogo from '../assets/St_Charles_Logo-removebg-preview (1).png'
// VoortrekkerBethLogo;
import VoortrekkerBethLogo from '../assets/Voortrekker_Bethlehem_Logo-removebg-preview (1).png'
// PearsonLogo;
import PearsonLogo from '../assets/Pearson_Logo-removebg-preview (1) (1).png'
// StAndrewsLogo;
import StAndrewsLogo from '../assets/St_Andrews_Logo-removebg-preview (1).png'
// DurbanvilleLogo;
import DurbanvilleLogo from '../assets/Durbanville_Logo.png'
// MilnertonLogo;
import MilnertonLogo from '../assets/Milnerton_Logo-removebg-preview (1).png'
// GlenwoodLogo;
import GlenwoodLogo from '../assets/Glenwood_Logo-removebg-preview (1).png'
// WesvaliaLogo;
import WesvaliaLogo from '../assets/Wesvalia_Logo-removebg-preview (1).png'
// StStithiansLogo;
import StStithiansLogo from '../assets/St_Stithians_Logo-removebg-preview (1).png'
// MiddelburgHSLogo;
import MiddelburgHSLogo from '../assets/Middelburg_Logo.png'

// CharlieHofmeyrLogo;
import CharlieHofmeyrLogo from '../assets/Charlie_Hofmeyr_Logo-removebg-preview (1).png'
// WorcesterGymLogo;
import WorcesterGymLogo from '../assets/Worcester_Logo-removebg-preview (1).png'
// KingDavidLogo;
import KingDavidLogo from '../assets/King_David_Logo-removebg-preview (1).png'
// SpringsBoysLogo;
import SpringsBoysLogo from '../assets/Spring_Boys_Logo-removebg-preview (1).png'
// AlbertonLogo;
import AlbertonLogo from '../assets/Alberton_High_Logo-removebg-preview (1).png'
// FourwaysLogo;
import FourwaysLogo from '../assets/Fourways_Logo-removebg-preview (1).png'
// StDunstansLogo;
import StDunstansLogo from '../assets/St_Dunstans_Logo-removebg-preview (1).png'
// CornwallHillLogo;
import CornwallHillLogo from '../assets/Cornwall_Logo-removebg-preview (1).png'
// StDavidsLogo;
import StDavidsLogo from '../assets/St_Davids_Logo-removebg-preview (1).png'
// StPetersLogo;
import StPetersLogo from '../assets/ST_Peters_Logo-removebg-preview (1).png'
// SutherlandLogo;
import SutherlandLogo from '../assets/Sutherland_Logo-removebg-preview (1).png'
// NorthcliffLogo;
import NorthcliffLogo from '../assets/Northcliff_Logo-removebg-preview (1).png'
// PotchBoysLogo;
import PotchBoysLogo from '../assets/Potch_Boys_Logo-removebg-preview (1).png'
// StBenedictsLogo;
import StBenedictsLogo from '../assets/St_Benedicts_Logo-removebg-preview (1).png'
//  BishopsLogo;
import BishopsLogo from '../assets/Bishops_Logo-removebg-preview (1).png'
//  GraemeCollegeLogo;
import GraemeCollegeLogo from '../assets/Graeme_Logo-removebg-preview (1).png'
//  HTSDrostdyLogo;
import HTSDrostdyLogo from '../assets/HTS_Drostdy_Logo-removebg-preview (1).png'
//  KearsneyCollegeLogo;
import KearsneyCollegeLogo from '../assets/Kearsney_College_Logo-removebg-preview (1).png'
//  NorthwoodLogo;
import NorthwoodLogo from '../assets/Northwood_Logo-removebg-preview (1).png'
//  PaulRoosGymLogo;
import PaulRoosGymLogo from '../assets/Paul_Roos_Gym_Logo-removebg-preview (1).png'
//  StAlbansCollegeLogo;
import StAlbansCollegeLogo from '../assets/St_Albans_College_Logo-removebg-preview (1).png'
//  WelkomGimLogo;
import WelkomGimLogo from '../assets/Welkom_Gim_Logo-removebg-preview (1).png'
//Heidelberg
import HeidelbergVolkskool from '../assets/HeidelbergVolkskool-removebg-preview.png'

//   Montana - got it
import Montanta from '../assets/MontanaLogo-removebg-preview.png'
//   Anker - got it
import Anker from '../assets/DieAnkerLogo-removebg-preview.png'
//   Secunda - got it
import Secunda from '../assets/SecundaLogo-removebg-preview.png'
//   Bergsig - got it
import Bergsig from '../assets/BergsigLogo-removebg-preview.png'
//   Potch Volkies - got it
import PotchVolkies from '../assets/PotchVolkiesLogo-removebg-preview.png'
//   Potch Gim - got it
import PotchGimmies from '../assets/PotchGimLogo-removebg-preview.png'
//   Licthenburg - got it
import Licthenburg from '../assets/LichtenBurgLogo-removebg-preview.png'
//   Witteberg - got it
import Witteberg from '../assets/WiitebergLogo-removebg-preview.png'


// Belville
import Belville from '../assets/Belville-removebg-preview.png'
// Bredasdorp
import BredasDorp from '../assets/Bredasdorp-removebg-preview.png'
// Daniel Pienaar
import DanielPienaar from '../assets/Daniel_Pienaar-removebg-preview.png'
// DF Malan
import DFMalan from '../assets/DF_Malan-removebg-preview.png'
// Empangeni
import Empangeni from '../assets/Empangeni-removebg-preview.png'
// Eshowe
import Eshowe from '../assets/Eshowe-removebg-preview.png'
// Germiston
import Germiston from '../assets/Germiston-removebg-preview.png'

// Greytown
import Greytown from '../assets/Greytown-removebg-preview.png'
// Hermanus
import Hermanus from '../assets/Hermanus-removebg-preview.png'
// Hugenote Wellington
import HugenoteWellington from '../assets/HugenoteWellington-removebg-preview.png'
// John Ross
import JohnRoss from '../assets/JohnRoss-removebg-preview.png'
// Langenhoven
import Langenhoven from '../assets/Langenhoven-removebg-preview.png'
// Montagu
import Montagu from '../assets/Montagu-removebg-preview.png'
// Pionier 
import Pionier from '../assets/Pionier-removebg-preview.png'
// Punt 
import Punt from '../assets/Punt-removebg-preview.png'
// Richardsbaai
import Richardsbaai from '../assets/Richardsbaai-removebg-preview.png'
// Riverwood 
import Riverwood from '../assets/Riverwood-removebg-preview.png'
// Strand 
import Strand from '../assets/Strand-removebg-preview.png'
// Swartland 
import Swartland from '../assets/Swartland-removebg-preview.png'
// Uplands 
import Uplands from '../assets/Uplands-removebg-preview.png'
// Vryheid
import Vryheid from '../assets/Vryheid-removebg-preview (1).png'


// async function LogoSelector(schoolName) {
//     switch (true) {

//         case schoolName.includes('Afrikaanse Hoër Seunskool'):
//             return AffiesLogoRB
//         case schoolName.includes('Hoërskool Ben Vorster'):
//             return BenVorster
//         case schoolName.includes('Boland Landbou'):
//             return BolandRB
//         case schoolName.includes('Benoni High'):
//             return BenoniHighRB
//         case schoolName.includes('Hoërskool Brandwag'):
//             return Brandwag
//         case schoolName.includes('Hoërskool Centurion'):
//             return CenturionRB
//         case schoolName.includes('CBC Boksburg'):
//             return CBCBoksburgRB
//         case schoolName.includes('Hoërskool Dr. EG Jansen'):
//             //  return EGJansenLogo
//             return EGJansenLogoRB
//         case schoolName.includes('Hoërskool Dr. E.G. Jansen'):
//             // return EGJansenLogo
//             return EGJansenLogoRB
//         case schoolName.includes('Hoërskool Eldoraigne'):
//             return EldoraigneRB
//         case schoolName.includes('Hoërskool Ermelo'):
//             return Ermelo
//         case schoolName.includes('Hoërskool Frikkie Meyer'):
//             return FrikkieMeyerRB
//         case schoolName.includes('Grey College'):
//             // return Grey
//             return GreyRB
//         case schoolName.includes('Hoërskool Helpmekaar'):
//             return Helpmekaar
//         case schoolName.includes('Hts John Vorster'):
//             return JohnVorster
//         case schoolName.includes('Hts Middelburg'):
//             return Middelburg
//         case schoolName.includes('Hoërskool Kempton Park'):
//             return Kempton
//         case schoolName.includes('Hoërskool Marais Viljoen'):
//             return maraisviljoenRB
//         case schoolName.includes('Hoërskool Marlow'):
//             return MarlowRB
//         case schoolName.includes('Hoërskool Menlo Park'):
//             return menloLogoRB
//         case schoolName.includes('Hoërskool Merensky'):
//             return Merensky
//         case schoolName.includes('Hoërskool Monument Park'):
//             return monnaslogoRB
//         case schoolName.includes('Hoërskool Nelspruit'):
//             return Nelspruit
//         case schoolName.includes('Hoërskool Noordheuwel'):
//             return NoordheuwelRB
//         case schoolName.includes('Hoërskool Oos Moot'):
//             return OosMoot
//         case schoolName.includes('Hoërskool Outeniqua'):
//             return OuteniquaRB
//         case schoolName.includes('Paarl Boys High'):
//             return PaarlBoysHighSchool
//         case schoolName.includes('Paarl Gimnasium'):
//             return PaarlGimRB
//         case schoolName.includes('Pietersburg Hoërskool'):
//             return PHSRB
//         case schoolName.includes('Hoërskool Piet Retief'):
//             return PietRetiefRB
//         case schoolName.includes('Hoërskool Framesby'):
//             return Framesby
//         case schoolName.includes('Selborne College'):
//             return Selborne
//         case schoolName.includes('Hugenote'):
//             return HugenoteRB
//         case schoolName.includes('Hoërskool Transvalia'):
//             return TransvaliaRB
//         case schoolName.includes('Hoërskool Waterkloof'):
//             //  return waterkloofLogo
//             return kloofrb
//         case schoolName.includes('Hoërskool Wonderboom'):
//             return Wonderboom
//         case schoolName.includes('Hoërskool Zwartkop'):
//             return Zwartkop
//         case schoolName.includes('Hoërskool Garsfontein'):
//             return GarsfonteinLogo
//         case schoolName.includes('Pretoria Boys High'):
//             return PretoriaBoysHigh
//         case schoolName.includes('Hoërskool Rustenburg'):
//             return Rustenburg
//         default:
//             return SquadControlWhiteRB
//     }
// }

// export default LogoSelector


//Combined
async function LogoSelector(schoolName) {
    switch (true) {
        case schoolName.includes('Affies') || schoolName.includes('Afrikaanse Hoër Seunskool'):
            return AffiesLogoRB;
        case schoolName.includes('Ben Vorster') || schoolName.includes('Hoërskool Ben Vorster'):
            return BenVorster;
        case schoolName.includes('Boland Landbou'):
            return BolandRB;
        case schoolName.includes('Benoni High'):
            return BenoniHighRB;
        case schoolName.includes('Brandwag') || schoolName.includes('Hoërskool Brandwag'):
            return Brandwag;
        case schoolName.includes('Centurion') || schoolName.includes('Hoërskool Centurion'):
            return CenturionRB;
        case schoolName.includes('CBC Boksburg'):
            return CBCBoksburgRB;
        case schoolName.includes('Dr. EG Jansen') || schoolName.includes('Hoërskool Dr. EG Jansen') || schoolName.includes('Hoërskool Dr. E.G. Jansen'):
            return EGJansenLogoRB;
        case schoolName.includes('Eldoraigne') || schoolName.includes('Hoërskool Eldoraigne'):
            return EldoraigneRB;
        case schoolName.includes('Ermelo') || schoolName.includes('Hoërskool Ermelo'):
            return Ermelo;
        case schoolName.includes('Frikkie Meyer') || schoolName.includes('Hoërskool Frikkie Meyer'):
            return FrikkieMeyerRB;
        case schoolName.includes('Grey College'):
            return GreyRB;
        case schoolName.includes('Hugenote'):
            return HugenoteRB;
        case schoolName.includes('Helpmekaar') || schoolName.includes('Hoërskool Helpmekaar'):
            return Helpmekaar;
        case schoolName.includes('John Vorster') || schoolName.includes('Hts John Vorster'):
            return JohnVorster;
        case schoolName.includes('Hts Mid'):
            return HtsMiddelburg;
        case schoolName.includes('Kempton Park') || schoolName.includes('Hoërskool Kempton Park'):
            return Kempton;
        case schoolName.includes('Marais Viljoen') || schoolName.includes('Hoërskool Marais Viljoen'):
            return maraisviljoenRB;
        case schoolName.includes('Marlow') || schoolName.includes('Hoërskool Marlow'):
            return MarlowRB;
        case schoolName.includes('Menlo Park') || schoolName.includes('Hoërskool Menlo Park'):
            return menloLogoRB;
        case schoolName.includes('Merensky') || schoolName.includes('Hoërskool Merensky'):
            return Merensky;
        case schoolName.includes('Monument') || schoolName.includes('Hoërskool Monument'):
            return monnaslogoRB;
        case schoolName.includes('Nelspruit') || schoolName.includes('Hoërskool Nelspruit'):
            return Nelspruit;
        case schoolName.includes('Noordheuwel') || schoolName.includes('Hoërskool Noordheuwel'):
            return NoordheuwelRB;
        case schoolName.includes('Oos Moot') || schoolName.includes('Hoërskool Oos Moot'):
            return OosMoot;
        case schoolName.includes('Outeniqua') || schoolName.includes('Hoërskool Outeniqua'):
            return OuteniquaRB;
        case schoolName.includes('Paarl Boys High'):
            return PaarlBoysHighSchool;
        case schoolName.includes('Paarl Gimnasium'):
            return PaarlGimRB;
        case schoolName.includes('Pietersburg') || schoolName.includes('Pietersburg Hoërskool'):
            return PHSRB;
        case schoolName.includes('Piet Retief') || schoolName.includes('Hoërskool Piet Retief'):
            return PietRetiefRB;
        case schoolName.includes('Framesby') || schoolName.includes('Hoërskool Framesby'):
            return Framesby;
        case schoolName.includes('Selborne College'):
            return Selborne;
        case schoolName.includes('Transvalia') || schoolName.includes('Hoërskool Transvalia'):
            return TransvaliaRB;
        case schoolName.includes('Waterkloof') || schoolName.includes('Hoërskool Waterkloof'):
            return kloofrb;
        case schoolName.includes('Wonderboom') || schoolName.includes('Hoërskool Wonderboom'):
            return Wonderboom;
        case schoolName.includes('Zwartkop') || schoolName.includes('Hoërskool Zwartkop'):
            return Zwartkop;
        case schoolName.includes('Garsfontein') || schoolName.includes('Hoërskool Garsfontein'):
            return GarsfonteinLogo;
        case schoolName.includes('Pretoria Boys High'):
            return PretoriaBoysHigh;
        case schoolName.includes('Rustenburg') || schoolName.includes('Hoërskool Rustenburg'):
            return Rustenburg;


        // Newly added schools
        case schoolName.includes('Oakdale'):
            return OakdaleLogo;
        case schoolName.includes('KES'):
            return KESLogo;
        case schoolName.includes('Jeppe'):
            return JeppeLogo;
        case schoolName.includes('DHS'):
            return DurbanHighLogo;
        case schoolName.includes('Durban High School'):
            return DurbanHighLogo;
        case schoolName.includes('Rondebosch'):
            return RondeboschLogo;
        case schoolName.includes('Stellenberg'):
            return StellenbergLogo;
        case schoolName.includes('Westville'):
            return WestvilleLogo;
        case schoolName.includes('Maritzburg College'):
            return MaritzburgCollegeLogo;
        case schoolName.includes('Grey High School'):
            return GreyHighLogo;
        case schoolName.includes('Wynberg Boys\' High'):
            return WynbergBoysLogo;
        case schoolName.includes('Michaelhouse'):
            return MichaelhouseLogo;
        case schoolName.includes('SACS'):
            return SACSLogo;
        case schoolName.includes('Hilton College'):
            return HiltonCollegeLogo;
        case schoolName.includes('Queens College'):
            return QueensCollegeLogo;
        case schoolName.includes('Diamantveld'):
            return DiamantveldLogo;
        case schoolName.includes('Nico Malan'):
            return NicoMalanLogo;
        case schoolName.includes('St Johns College'):
            return StJohnsLogo;
        case schoolName.includes('Kingswood College'):
            return KingswoodLogo;
        case schoolName.includes('St Charles College'):
            return StCharlesLogo;
        case schoolName.includes('Voortrekker Beth'):
            return VoortrekkerBethLogo;
        case schoolName.includes('Pearson'):
            return PearsonLogo;
        case schoolName.includes('St Andrews College'):
            return StAndrewsLogo;
        case schoolName.includes('Durbanville'):
            return DurbanvilleLogo;
        case schoolName.includes('Milnerton'):
            return MilnertonLogo;
        case schoolName.includes('Glenwood'):
            return GlenwoodLogo;
        case schoolName.includes('Wesvalia'):
            return WesvaliaLogo;
        case schoolName.includes('St Stithians'):
            return StStithiansLogo;
        case schoolName.includes('Middelburg HS'):
            return MiddelburgHSLogo;
        case schoolName.includes('Charlie Hofmeyr'):
            return CharlieHofmeyrLogo;
        case schoolName.includes('Worcester Gym'):
            return WorcesterGymLogo;
        case schoolName.includes('King David'):
            return KingDavidLogo;


        case schoolName.includes('Bishops'):
            return BishopsLogo;
        case schoolName.includes('Graeme College'):
            return GraemeCollegeLogo;
        case schoolName.includes('HTS Drostdy'):
            return HTSDrostdyLogo;

        case schoolName.includes('Kearsney College'):
            return KearsneyCollegeLogo;
        case schoolName.includes('Northwood'):
            return NorthwoodLogo;
        case schoolName.includes('Paul Roos Gym'):
            return PaulRoosGymLogo;
        case schoolName.includes('St Albans College'):
            return StAlbansCollegeLogo;
        case schoolName.includes('Welkom Gim'):
            return WelkomGimLogo;


        case schoolName.includes('Springs Boys'):
            return SpringsBoysLogo;
        case schoolName.includes('Alberton'):
            return AlbertonLogo;
        case schoolName.includes('Fourways'):
            return FourwaysLogo;
        case schoolName.includes('St Dunstan\'s'):
            return StDunstansLogo;
        case schoolName.includes('Cornwall Hill'):
            return CornwallHillLogo;
        case schoolName.includes('St David\'s'):
            return StDavidsLogo;
        case schoolName.includes('St Peter\'s'):
            return StPetersLogo;
        case schoolName.includes('Sutherland'):
            return SutherlandLogo;
        case schoolName.includes('Northcliff'):
            return NorthcliffLogo;
        case schoolName.includes('Potch Boys'):
            return PotchBoysLogo;
        case schoolName.includes('Hoër Volkskool Heidelberg'):
            return HeidelbergVolkskool;
        case schoolName.includes('St Benedict\'s'):
            return StBenedictsLogo;
        case schoolName.includes('Montana'):
            return Montanta;
        case schoolName.includes('Die Anker'):
            return Anker;
        case schoolName.includes('Hoër Volkskool Potchefstroom'):
            return PotchVolkies;
        case schoolName.includes('Secunda'):
            return Secunda;
        case schoolName.includes('Bergsig'):
            return Bergsig;
        case schoolName.includes('Potchefstroom Gimnasium'):
            return PotchGimmies;
        case schoolName.includes('Lichtenburg'):
            return Licthenburg;
        case schoolName.includes('Witteberg'):
            return Witteberg;
        case schoolName.includes('Dinamika'):
            return Dinamika;


        case schoolName.includes('Belville'):
            return Belville;
        case schoolName.includes('Bredasdorp'):
            return BredasDorp;
        case schoolName.includes('Daniel Pienaar'):
            return DanielPienaar;
        case schoolName.includes('DF Malan'):
            return DFMalan;
        case schoolName.includes('Empangeni'):
            return Empangeni;
        case schoolName.includes('Eshowe'):
            return Eshowe;

        case schoolName.includes('Germiston'):
            return Germiston;
        case schoolName.includes('Greytown'):
            return Greytown;

        case schoolName.includes('Hermanus'):
            return Hermanus;
        case schoolName.includes('Hugenote Wellington'):
            return HugenoteWellington;


        case schoolName.includes('John Ross'):
            return JohnRoss;
        case schoolName.includes('Langenhoven'):
            return Langenhoven;

        case schoolName.includes('Montagu'):
            return Montagu;
        case schoolName.includes('Pionier'):
            return Pionier;


        case schoolName.includes('Punt'):
            return Punt;
        case schoolName.includes('Richardsbaai'):
            return Richardsbaai;

        case schoolName.includes('Riverwood'):
            return Riverwood;
        case schoolName.includes('Strand'):
            return Strand;

        case schoolName.includes('Swartland'):
            return Swartland;
        case schoolName.includes('Uplands'):
            return Uplands;

        case schoolName.includes('Vryheid'):
            return Vryheid;
        // Belville
        // Bredasdorp
        // Daniel Pienaar
        // DF Malan

        // Empangeni
        // Eshowe

        // Germiston
        // Greytown

        // Hermanus
        // Hugenote Wellington

        // John Ross
        // Langenhoven
        // Montagu
        // Pionier 


        // Punt 
        // Richardsbaai
        // Riverwood 
        // Strand 
        // Swartland 
        // Uplands 
        // Vryheid

        default:
            return SquadControlWhiteRB;
    }
}

export default LogoSelector;


////Just new Naming Structure
// import React from 'react'
// import waterkloofLogo from '../assets/kloof.jpeg'
// import kloofrb from '../assets/kloofRB.png'
// import maraisviljoen from '../assets/maraisviljoen.jpeg'
// import maraisviljoenRB from '../assets/maraisviljoenRB.png'
// import monnaslogo from '../assets/Monnas Logo.jpeg'
// import monnaslogoRB from '../assets/MonnasRB.png'
// import affiesWapen from '../assets/Affies_wapen.jpg'
// import AffiesLogo from '../assets/affies-logo.png'
// import AffiesLogoRB from '../assets/AffiesLogoRB.png'
// import EGJansenLogo from '../assets/EGJansenLogo.png'
// import EGJansenLogoRB from '../assets/EGJansenLogoRB.png'
// import MenloLogo from '../assets/menlo.jpeg'
// import menloLogoRB from '../assets/menlo-RB.png'
// import SquadControlLogo from '../assets/SquadControlWhite.png'
// import SquadControlWhiteRB from '../assets/SquadControlWhiteRB.png'
// import BenVorster from '../assets/Ben Vorster.jpeg'
// import Boland from '../assets/Boland.jpeg'
// import BolandRB from '../assets/BolandRB.png'
// import Brandwag from '../assets/Brandwag.png'
// import Centurion from '../assets/Centurion.jpeg'
// import CenturionRB from '../assets/CenturionRB.png'
// import Eldoraigne from '../assets/Eldoraigne.jpeg'
// import EldoraigneRB from '../assets/EldoraigneRB.png'
// import Ermelo from '../assets/Ermelo.png'
// import FrikkieMeyer from '../assets/Frikkie Meyer.png'
// import FrikkieMeyerRB from '../assets/Frikkie_MeyerRB.png'
// import Garsfontein from '../assets/garsfontein.png'
// import Grey from '../assets/Grey College.jpeg'
// import GreyRB from '../assets/Grey College RB.png'
// import Helpmekaar from '../assets/HelpMekaar.png'
// import Rustenburg from '../assets/Rustenburg-removebg-preview.png'
// import JohnVorster from '../assets/HTS John Vorster.png'
// import Middelburg from '../assets/HTS Middelburg.png'
// import Kempton from '../assets/Kempton Park.png'
// import Marlow from '../assets/Marlow Landbou.png'
// import MarlowRB from '../assets/Marlow_LandbouRB.png'
// import Merensky from '../assets/Merensky.png'
// import Nelspruit from '../assets/Nelspruit.png'

// import Noordheuwel from '../assets/Noordheuwel.jpeg'
// import NoordheuwelRB from '../assets/Noordheuwel-RB.png'
// import OosMoot from '../assets/oos moot.png'
// import OuteniquaRB from '../assets/Outeniqua-RB.png'
// import PaarlBoysHighSchool from "../assets/Paarl Boys' High School.png"
// import PaarlGim from '../assets/paarlGim.jpg'
// import PaarlGimRB from '../assets/paarlGim-RB.png'
// import PHS from '../assets/PHS.png'
// import PHSRB from '../assets/PHS-RB.png'
// import PretoriaBoysHigh from '../assets/PBH-RB.png'

// //import PietRetief from '../assets/Piet Retief.png'
// import PietRetiefRB from '../assets/Piet_Retief-removebg-preview.png'

// import Transvalia from '../assets/Transvalia.png'
// import TransvaliaRB from '../assets/Transvalia-RB.png'
// import Wonderboom from '../assets/Wonderboom.png'
// import Zwartkop from '../assets/Zwartkop.png'

// import GarsfonteinLogo from '../assets/garsfonteinLogo.png'
// import Framesby from '../assets/framesbyLogo.png'
// import Selborne from '../assets/selbourneLogo.png'

// async function LogoSelector(schoolName) {
//     // console.log('Opponent received in function: ', schoolName)
//     switch (true) {

//         case schoolName.includes('Affies'):
//             return AffiesLogoRB
//         case schoolName.includes('Ben Vorster'):
//             return BenVorster
//         case schoolName.includes('Boland Landbou'):
//             return BolandRB
//         case schoolName.includes('Brandwag'):
//             return Brandwag
//         case schoolName.includes('Centurion'):
//             return CenturionRB
//         case schoolName.includes('CBC Boksburg'):
//             return CenturionRB
//         case schoolName.includes('Dr. EG Jansen'):
//             //  return EGJansenLogo
//             return EGJansenLogoRB
//         case schoolName.includes('Eldoraigne'):
//             return EldoraigneRB
//         case schoolName.includes('Ermelo'):
//             return Ermelo
//         case schoolName.includes('Frikkie Meyer'):
//             return FrikkieMeyerRB
//         case schoolName.includes('Grey College'):
//             // return Grey
//             return GreyRB
//         case schoolName.includes('Helpmekaar'):
//             return Helpmekaar
//         case schoolName.includes('John Vorster'):
//             return JohnVorster
//         case schoolName.includes('Middelburg'):
//             return Middelburg
//         case schoolName.includes('Kempton Park'):
//             return Kempton
//         case schoolName.includes('Marais Viljoen'):
//             return maraisviljoenRB
//         case schoolName.includes('Marlow'):
//             return MarlowRB
//         case schoolName.includes('Menlo Park'):
//             return menloLogoRB
//         case schoolName.includes('Merensky'):
//             return Merensky
//         case schoolName.includes('Monument'):
//             return monnaslogoRB
//         case schoolName.includes('Nelspruit'):
//             return Nelspruit
//         case schoolName.includes('Noordheuwel'):
//             return NoordheuwelRB
//         case schoolName.includes('Oos Moot'):
//             return OosMoot
//         case schoolName.includes('Outeniqua'):
//             return OuteniquaRB
//         case schoolName.includes('Paarl Boys High'):
//             return PaarlBoysHighSchool
//         case schoolName.includes('Paarl Gimnasium'):
//             return PaarlGimRB
//         case schoolName.includes('Pietersburg'):
//             return PHSRB
//         case schoolName.includes('Piet Retief'):
//             return PietRetiefRB
//         case schoolName.includes('Framesby'):
//             return Framesby
//         case schoolName.includes('Selborne College'):
//             return Selborne
//         case schoolName.includes('Transvalia'):
//             return TransvaliaRB
//         case schoolName.includes('Waterkloof'):
//             //  return waterkloofLogo
//             return kloofrb
//         case schoolName.includes('Wonderboom'):
//             return Wonderboom
//         case schoolName.includes('Zwartkop'):
//             return Zwartkop
//         case schoolName.includes('Garsfontein'):
//             return GarsfonteinLogo
//         case schoolName.includes('Pretoria Boys High'):
//             return PretoriaBoysHigh
//         case schoolName.includes('Rustenburg'):
//             return Rustenburg
//         default:
//             return SquadControlWhiteRB
//     }
// }

// export default LogoSelector





// Schools from the updated list that do not have logos:
// Oakdale - got it
// KES (King Edward VII School)  - got it
// Jeppe  - got it
// DHS (Durban High School)  - got it
// Rondebosch  - got it
// Stellenberg  - got it remove BG
// Westville  - got it
// Maritzburg College  - got it
// Grey High School (different from Grey College)  - got it
// Wynberg BH (Wynberg Boys' High)  - got it
// Michaelhouse  - got it
// SACS (South African College Schools)  - got it
// Hilton College  - got it
// Queens College  - got it
// Diamantveld  - got it
// Nico Malan  - got it
// St Johns College  - got it
// Kingswood College  - got it
// St Charles College  - got it
// Voortrekker Beth (Voortrekker High)  - got it
// Pearson  - got it
// St Andrews College  - got it
// Durbanville  - got it remove bg
// Milnerton  - got it
// Glenwood  - got it
// Wesvalia  - got it CROP
// St Stithians  - got it
// Middelburg HS (different from HTS Middelburg)  - got it REMOVE BG
// Charlie Hofmeyr  - got it
// Worcester Gym   - got it
//King David   - got it
//Springs Boys   - got it
//Alberton - got it
//Fourways f - got it
//St Dunstan's  - got it
//Cornwall Hill   - got it
// St David's  - got it
// St Peter's  - got it
// Sutherland   - got it
// Northcliff - got it
// Potch Boys   - got it
// St Benedict's - got it
//HeidelbegVolkies - got and removed BG


// BGS to remove:
// KES (King Edward VII School)  - got it
// Jeppe  - got it
// DHS (Durban High School)  - got it
// Rondebosch  - got it
// Westville  - got it
// Maritzburg College  - got it
// Grey High School (different from Grey College)  - got it
// Wynberg BH (Wynberg Boys' High)  - got it
// Michaelhouse  - got it
// SACS (South African College Schools)  - got it
// Hilton College  - got it
// Queens College  - got it
// Diamantveld  - got it
// Nico Malan  - got it
// St Johns College  - got it
// Kingswood College  - got it
// St Charles College  - got it
// Voortrekker Beth (Voortrekker High)  - got it
// Pearson  - got it
// St Andrews College  - got it
// Milnerton  - got it
// Glenwood  - got it
// Wesvalia  - got it CROP
// St Stithians  - got it
// Middelburg HS (different from HTS Middelburg)  - got it REMOVE BG
// Charlie Hofmeyr  - got it
// Worcester Gym   - got it
//King David   - got it
//Springs Boys   - got it
//Alberton - got it
//Fourways f - got it
//St Dunstan's  - got it
//Cornwall Hill   - got it
// St David's  - got it
// St Peter's  - got it
// Sutherland   - got it
// Northcliff - got it
// Potch Boys   - got it
// St Benedict's - got it




// NUWES
//   Montana - got it
//   Anker - got it
//   Secunda - got it
//   Bergsig - got it
//   Potch Volkies - got it
//   Potch Gim - got it
//   Licthenburg - got it
//   Witteberg - got it




// EXTRAS
// Belville
// Bredasdorp
// Daniel Pienaar
// DF Malan
// Empangeni
// Eshowe
// Germiston
// Greytown
// Hermanus
// Hugenote Wellington
// John Ross
// Langenhoven
// Montagu
// Pionier
// Punt
// Richardsbaai
// Riverwood
// Strand
// Swartland
// Uplands
// Vryheid
