import React from 'react'
import { Button } from '@mui/material'
import SubDialog from './subDialog';
import { useSelector } from 'react-redux';
import ImportExportIcon from '@mui/icons-material/ImportExport';


function Substitutions(props) {
  const [open, setOpen] = React.useState(false);
  const [subClicked, setSubClicked] = React.useState(false);
  const stateTeam = useSelector((state) => state.gameday.startingTeam)

  const newTeam = props.team

  // console.log('New team: ', newTeam)

  function handleClick() {
    setSubClicked(true);
    setOpen(true);
    console.log('Handle click has been clicked. ')
  }

  function handleClose() {
    setSubClicked(false);
    setOpen(false);
  }

  function handleNewSubmit() {
    setSubClicked(false);
    setOpen(false)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        <ImportExportIcon fontSize='small' style={{ marginRight: '10px' }} />
        Add Sub
      </Button>
      {subClicked && <SubDialog open={open} handleClose={handleClose} stateTeam={newTeam} />}
    </div>
  );
}

export default Substitutions;