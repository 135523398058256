import React from 'react';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Button, TextField, Select, MenuItem, Divider, Snackbar, Typography } from '@mui/material';

// import waterkloofLogo from '../../../assets/waterkloof logo.png';
import waterkloofLogo from '../../../assets/kloof.jpeg'
import maraisviljoen from '../../../assets/maraisviljoen.jpeg'
import monnaslogo from '../../../assets/Monnas Logo.jpeg'
import affiesWapen from '../../../assets/Affies_wapen.jpg'
import EGJansenLogo from '../../../assets/EGJansenLogo.png'
import Logo from '../../../assets/Squad Control Logo New.jpeg'
import { Add, Clear, Remove } from '@mui/icons-material';
import { setOurScore, setOpponentScore, setGameDayStarted, updatePlayerGameTime, emtpyActions, setStateManOfTheMatch, updatePlayerStatsMOTM } from '../gamedaySlice';
import { useDispatch } from 'react-redux';
import { setRunningTimer } from '../timerSlice';
import ManOfTheMatchModal from '../motmModal';
import { useState } from 'react';
import { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { gameRequests } from '../../../services/api-requests';
import LogoSelector from '../../../services/logoSelector';

function GameReportGenerator(props) {
   // console.log('Props received in report: ', props)
    const dispatch = useDispatch();
    const [schoolImage, setSchoolImage] = useState(null);
    const [opponentImage, setOpponentImage] = useState(null);
    const [manOfTheMatchDone, setManOfTheMatchDone] = useState(false)
    const [error, setError] = useState(false);
    const [openNotification, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [additionalMessage, setAdditionalMessage] = useState('');
    const stateOpponent = useSelector((state) => state.gameday.opponent);
    const stateAgeGroup = useSelector((state) => state.gameday.ageGroup);
    const actions = useSelector((state) => state.gameday.stateActions);
    const ourScore = useSelector((state) => state.gameday.ourScore);
    const opponentScore = useSelector((state) => state.gameday.opponentScore);
    const opponent = useSelector((state) => state.gameday.opponent);
    const kickoffTime = useSelector((state) => state.gameday.kickoffTime);
    const activeTeam = useSelector((state) => state.gameday.activeTeam)
    const gameHalfLength = useSelector((state) => state.timer.halfLength)
    const playerStats = useSelector((state) => state.gameday.GameObject.playerStats)
    const impactPlayersArray = useSelector((state) => state.gameday.impactPlayersArray)
    const stateManOfTheMatchObject = useSelector((state) => state.gameday.StateManOfTheMatch)
    const ManOfTheMatch = `${stateManOfTheMatchObject?.firstName} ${stateManOfTheMatchObject?.lastName}`
   // console.log('Man of the match Received in report: ', ManOfTheMatch)
    const [isManOfTheMatchModalOpen, setManOfTheMatchModalOpen] = useState(false);
    const [manOftheMatchName, setManOfTheMatchName] = useState('');
    const schoolName = useSelector((state) => state.user.schoolName)
    const gameObject = useSelector((state) => state.gameday.GameObject)
    const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))
    const extraStats = useSelector((state) => state.gameday.GameObject.extraStats)
    const knockOns = useSelector((state) => state.gameday.GameObject.extraStats?.knockOns)
    //Lineouts
    const lineoutsWon = useSelector((state) => state.gameday.GameObject.extraStats?.lineoutsWon)
    const lineoutsLost = useSelector((state) => state.gameday.GameObject.extraStats?.lineoutsLost)
    //Scrums 
    const scrumsWon = useSelector((state) => state.gameday.GameObject.extraStats?.scrumsWon)
    const scrumsLost = useSelector((state) => state.gameday.GameObject.extraStats?.scrumsLost)

    const [lineOutWin, setLineOutWin] = useState(null);
    const [scrumWin, setScrumWin] = useState(null);

    useEffect(() => {
        getImage();
        getOpponentImage(stateOpponent);
    }, [schoolName, stateOpponent]);


    const getImage = async () => {
        const image = await LogoSelector(schoolName);
        // console.log('image received: ', image)
        setSchoolImage(image);
    };

    const getOpponentImage = async (opponent) => {
        const image = await LogoSelector(opponent);
        setOpponentImage(image);
    };

    useEffect(() => {
        // Calculate the lineout win %
        const lineoutTotal = lineoutsLost + lineoutsWon;
        const calculatedLineOutWin = lineoutTotal !== 0 ? parseInt((lineoutsWon / lineoutTotal) * 100) : 0;
        setLineOutWin(calculatedLineOutWin);

        // Calculate the scrum win %
        const scrumTotal = scrumsLost + scrumsWon;
        const calculatedScrumWin = scrumTotal !== 0 ? parseInt((scrumsWon / scrumTotal) * 100) : 0;
        setScrumWin(calculatedScrumWin);

    }, [lineoutsWon, lineoutsLost, scrumsWon, scrumsLost]);

    function parseAction(action) {

        if (!action || typeof action !== 'string') {
            return ['', '', ''];
        }

        // Parse the action to extract key details
        const parts = action.split(' with ');


        let actionType
        let newActionType
        let myPlayer
        if (action.includes(' by ')) {
            actionType = parts[0].split(' by ');

            newActionType = actionType[0]
            myPlayer = actionType[1]
            //Card dealt with in here sommer
        }
        //Handle opponent actions
        else if (action.includes(`${opponent}`)) {

            newActionType = parts[0]
        }
        else if (action.includes(' with ')) {
            actionType = parts[0].split(' with ');

            //Sub functionality:
            if (action.includes('Subbed')) {
                newActionType = 'Subbed'

                // Split the string into three parts based on 'Subbed', 'for', and 'with' keywords
                actionType = action.split('Subbed');
                const subAction = actionType[1].trim();
                const secondPart = subAction.split(' for ')[0].trim();
                const thirdPart = subAction.split(' for ')[1].split(' with ')[0].trim();

                // Combine parts 2 and 3
                myPlayer = `${secondPart} for ${thirdPart}`;

            }

            if (action.includes('won') || action.includes('lost') || action.includes('Knock-on')) {
             //   console.log('Action is: ', action)
                newActionType = action.slice(0, action.indexOf('with'))
            }
        }
        else {
            newActionType = parts[0]
        }


        const time = parts[1]

        return [newActionType, myPlayer, time];
    }

    function handleEndGame() {
        setManOfTheMatchModalOpen(true)
    }

    // //The function that returns instead of dispatches
    // function calculateGameTime() {
    //     const TotalGameLength = gameHalfLength * 2;

    //     // Create a copy of playerStats to avoid mutating the state directly
    //     let updatedPlayerStats = [...playerStats];

    //     // Go through all the players
    //     updatedPlayerStats.forEach((player, index) => {
    //         if (!impactPlayersArray.some((impactPlayer) => impactPlayer.player === player.playerId)) {
    //             // Check if he is a reserve
    //             const isReserve = props.team.some((reservePlayer) => reservePlayer.id === player.playerId && reservePlayer.isReserve);

    //             // Player is not in impactPlayersArray and gametime = 0 means played the whole game
    //             if (isReserve && player.gameTime === 0) {
    //                 // Set gameTime to 0 for reserve players who did not play
    //                 updatedPlayerStats[index] = {
    //                     ...player,
    //                     gameTime: 0,
    //                 };
    //             } else if (player.gameTime === 0) {
    //                 // Player is not a reserve and gameTime is 0, set gameTime to TotalGameLength
    //                 updatedPlayerStats[index] = {
    //                     ...player,
    //                     gameTime: TotalGameLength,
    //                 };
    //             }
    //         } else {
    //             // Player is in impactPlayersArray means came off the bench
    //             const correspondingImpactPlayer = impactPlayersArray.find(
    //                 (impactPlayer) => impactPlayer.player === player.playerId
    //             );

    //             if (correspondingImpactPlayer) {
    //                 // Set gameTime for players who came off the bench
    //                 updatedPlayerStats[index] = {
    //                     ...player,
    //                     gameTime: TotalGameLength - correspondingImpactPlayer.startingTime,
    //                 };
    //             }
    //         }
    //     });

    //     // Return the updated playerStats
    //     return updatedPlayerStats;
    // }

    //Combo of the 2 functions:
    function calculateGameTime() {
        const TotalGameLength = gameHalfLength * 2;

        // Create a copy of playerStats to avoid mutating the state directly
        let updatedPlayerStats = [...playerStats];

        // Go through all the players
        updatedPlayerStats.forEach((player, index) => {
            if (!impactPlayersArray.some((impactPlayer) => impactPlayer.player === player.playerId)) {
                // Check if he is a reserve
                const isReserve = props.team.some((reservePlayer) => reservePlayer.id === player.playerId && reservePlayer.isReserve);

                // Player is not in impactPlayersArray and gametime = 0 means played the whole game
                if (isReserve && player.gameTime === 0) {
                    // Set gameTime to 0 for reserve players who did not play
                    updatedPlayerStats[index] = {
                        ...player,
                        gameTime: 0,
                    };

                    // Dispatch for reserve players who did not play
                    dispatch(updatePlayerGameTime({
                        type: 'completeGame',
                        player: player.playerId,
                        gameTime: 0,
                    }));
                } else if (player.gameTime === 0) {
                    // Player is not a reserve and gameTime is 0, set gameTime to TotalGameLength
                    updatedPlayerStats[index] = {
                        ...player,
                        gameTime: TotalGameLength,
                    };

                    // Dispatch for players who completed the whole game
                    dispatch(updatePlayerGameTime({
                        type: 'completeGame',
                        player: player.playerId,
                        gameTime: TotalGameLength,
                    }));
                }
                else if (player.cardId === 2) {
                    // Player received a yellow card, minus 5 if 14 else minus 10
                    const cardDeduction = ageGroup === 14 ? 5 : 10; // Deduction based on ageGroup
                    const newGameTime = TotalGameLength - cardDeduction;

                    updatedPlayerStats[index] = {
                        ...player,
                        gameTime: newGameTime,
                    };

                    // Dispatch for players who received a card
                    dispatch(updatePlayerGameTime({
                        type: 'card',
                        player: player.playerId,
                        gameTime: newGameTime, // Update gameTime based on deduction
                    }));
                }
            } else {
                // Player is in impactPlayersArray means came off the bench
                const correspondingImpactPlayer = impactPlayersArray.find(
                    (impactPlayer) => impactPlayer.player === player.playerId
                );

                if (correspondingImpactPlayer) {
                    // Set gameTime for players who came off the bench
                    updatedPlayerStats[index] = {
                        ...player,
                        gameTime: TotalGameLength - correspondingImpactPlayer.startingTime,
                    };

                    // Dispatch for players who came off the bench
                    dispatch(updatePlayerGameTime({
                        type: 'impact',
                        player: player.playerId,
                        gameTime: TotalGameLength - correspondingImpactPlayer.startingTime,
                    }));
                }
            }
        });

        // Return the updated playerStats
        return updatedPlayerStats;
    }



    // function calculateGameTime() {
    //     const TotalGameLength = gameHalfLength * 2;

    //     // Go through all the players
    //     playerStats.forEach((player) => {
    //         if (!impactPlayersArray.some((impactPlayer) => impactPlayer.player === player.playerId)) {
    //             // Check if he is a reserve
    //             const isReserve = props.team.some((reservePlayer) => reservePlayer.id === player.playerId && reservePlayer.isReserve);

    //             // Player is not in impactPlayersArray and gametime = 0 means played the whole game
    //             if (isReserve && player.gameTime === 0) {
    //                 // Set gameTime to 0 for reserve players who did not play
    //                 dispatch(updatePlayerGameTime({
    //                     type: 'completeGame',
    //                     player: player.playerId,
    //                     gameTime: 0,
    //                 }));
    //             } else if (player.gameTime === 0) {
    //                 // Player is not a reserve and gameTime is 0, set gameTime to TotalGameLength
    //                 dispatch(updatePlayerGameTime({
    //                     type: 'completeGame',
    //                     player: player.playerId,
    //                     gameTime: TotalGameLength,
    //                 }));
    //             }
    //         } else {
    //             // Player is in impactPlayersArray means came off the bench
    //             const correspondingImpactPlayer = impactPlayersArray.find(
    //                 (impactPlayer) => impactPlayer.player === player.playerId
    //             );

    //             if (correspondingImpactPlayer) {
    //                 // Set gameTime for players who came off the bench
    //                 dispatch(updatePlayerGameTime({
    //                     type: 'impact',
    //                     player: player.playerId,
    //                     gameTime: TotalGameLength - correspondingImpactPlayer.startingTime,
    //                 }));
    //             }
    //         }
    //     });
    // }

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            if (!error) {
                generateGameReport();
                dispatch(setOpponentScore({ type: 'end', action: 0 }));
                dispatch(setOurScore(0));
                dispatch(setRunningTimer('00:00:00'))
                dispatch(setGameDayStarted(false));
                dispatch(emtpyActions())
            }

            return;
        };

        if (!error) {
            generateGameReport();
            dispatch(setOpponentScore({ type: 'end', action: 0 }));
            dispatch(setOurScore(0));
            dispatch(setRunningTimer('00:00:00'))
            dispatch(setGameDayStarted(false));
            dispatch(emtpyActions())
        }

        generateGameReport();
        dispatch(setOpponentScore({ type: 'end', action: 0 }));
        dispatch(setOurScore(0));
        dispatch(setRunningTimer('00:00:00'))
        dispatch(setGameDayStarted(false));
        dispatch(emtpyActions())
        setNotificationOpen(false);
    };


    // async function handleManOfTheMatch(player) {
    //     console.log('The player received that is man of the match: ', player)

    //     if (player) {

    //         calculateGameTime()

    //         //Submit the game data
    //         // {
    //         //     "gameId": 0,
    //         //     "score": 0,
    //         //     "opponentTries": 0,
    //         //     "opponentScore": 0,
    //         //     "playerStats": [
    //         //       {
    //         //         "playerId": 0,
    //         //         "pointsMade": 0,
    //         //         "triesScored": 0,
    //         //         "conversionsMade": 0,
    //         //         "conversionsMissed": 0,
    //         //         "gameTime": 0,
    //         //         "cardId": 0,
    //         //         "isManOfTheMatch": true
    //         //       }
    //         //     ],
    //         //     "extraStats": {
    //         //       "knockOns": 0,
    //         //       "lineoutsWon": 0,
    //         //       "lineoutsLost": 0,
    //         //       "scrumsWon": 0,
    //         //       "scrumsLost": 0
    //         //     }
    //         //   }

    //         const newGameObject = {
    //             ...gameObject,
    //             playerStats: gameObject.playerStats.map((storePlayer) => {
    //                 if (storePlayer.playerId === player) {
    //                     return {
    //                         ...storePlayer,
    //                         isManOfTheMatch: true
    //                     };
    //                 } else {
    //                     return {
    //                         ...storePlayer,
    //                         isManOfTheMatch: false
    //                     };
    //                 }
    //             })
    //         };


    //         console.log('Going to be sending over this object: ', newGameObject)

    //         //Remove when doing backend connecting:
    //         generateGameReport();

    //         // try {
    //         //     const response = await gameRequests.submitGameData(teamId, newGameObject)

    //         //     if (response.status === 200) {

    //         //         setNotificationMessage('Game Data Submitted');
    //         //         setAdditionalMessage('Click to generate report')
    //         //         setError(false);
    //         //         setNotificationOpen(true);
    //         //     }
    //         //     else {
    //         //         setNotificationMessage('Could not submit game data');
    //         //         setAdditionalMessage('Please try again')
    //         //         setError(true);
    //         //         setNotificationOpen(true);
    //         //     }
    //         // } catch (error) {
    //         //     console.log('Error: ', error)
    //         // } finally {

    //         // }

    //     }
    // }


    async function handleManOfTheMatch(player) {
       // console.log('The player received that is man of the match: ', player)

        if (player) {
            const updatedPlayerStats = calculateGameTime();

            // Update the playerStats in gameObject with the calculated values
            const newGameObject = {
                ...gameObject,
                playerStats: gameObject.playerStats.map((storePlayer) => {
                    const updatedPlayer = updatedPlayerStats.find((updatedPlayer) => updatedPlayer.playerId === storePlayer.playerId);

                    if (updatedPlayer) {
                        return {
                            ...storePlayer,
                            gameTime: updatedPlayer.gameTime,
                            // Add other properties as needed
                        };
                    } else {
                        return storePlayer;
                    }
                }),
            };

            // Mark the man of the match
            const updatedManOfTheMatch = newGameObject.playerStats.map((storePlayer) => {
                if (storePlayer.playerId === player) {
                    return {
                        ...storePlayer,
                        isManOfTheMatch: true,
                    };
                } else {
                    return {
                        ...storePlayer,
                        isManOfTheMatch: false,
                    };
                }
            });

            // Update the newGameObject with the marked man of the match
            newGameObject.playerStats = updatedManOfTheMatch;

         //   console.log('Going to be sending over this object: ', newGameObject);

            // Dispatch the action to set the man of the match
            dispatch(updatePlayerStatsMOTM(player));

            // // Remove when doing backend connecting:
            // generateGameReport();
            // //Remember to remove

            try {

             //   console.log('TeamID: ', props.teamId)
                const response = await gameRequests.submitGameData(props.teamId, newGameObject)

                if (response.status === 200) {
                    setNotificationMessage('Game Data Submitted');
                    setAdditionalMessage('Click to generate report');
                    setError(false);

                }
                else {
                    setNotificationMessage('Could not submit game data');
                    setAdditionalMessage('Please try again');
                    setError(true);

                }
            } catch (error) {
                console.log('Error: ', error)
            } finally {
                setNotificationOpen(true);

            }
        }
    }


    function loadImage(src) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = src;
        });
    }

    async function loadImages(opponent) {
      //  console.log('Opponent received in function: ', opponent)
        switch (true) {
            //New Func
            case opponent.includes('ansen'):
                console.log('EG Jansen hit: ')
                return EGJansenLogo
            case opponent.includes('onnas'):
                console.log('Monnas hit: ')
                return monnaslogo
            case opponent.includes('Monument'):
                console.log('Monnas hit: ')
                return monnaslogo
            case opponent.includes('kloof'):
                console.log('Kloof hit: ')
                return waterkloofLogo;
            case opponent.includes('ffies'):
                console.log('Affies hit: ')
                return affiesWapen
            case opponent.includes('iljoen'):
                console.log('Marais hit: ')
                return maraisviljoen
            default:
                return null
        }
    }


    async function generateGameReport() {
     //   console.log('SHOULD HAVE BEEN HIT')
        const opponent = stateOpponent;
        const ageGroup = stateAgeGroup;


        // Create a new jsPDF instance
        const doc = new jsPDF();

        // Set the font size and style
        doc.setFontSize(12);


        const topTextOptions = { fontSize: 16, fontStyle: 'italic', textColor: 'white' };

        // Add Waterkloof logo to the PDF
        const logoWidth = 60;
        const logoHeight = 50;

        const mySchoolLogo = await loadImages(schoolName)
        doc.addImage(schoolImage, 'PNG', 10, 5, logoWidth, logoHeight);

        // const oppImage = await loadImages(stateOpponent)
        // console.log('Opponent Image: ', oppImage)

        doc.addImage(opponentImage, 'PNG', 145, 5, logoWidth, logoHeight);

        // // Build the report content
        // // Build the report content with styled text
        // doc.setFont('helvetica', 'bold');
        // doc.setTextColor('#000000'); // Set text color to blue
        // doc.setFontSize(16);
        // doc.text(`${schoolName}`, 75, 20,);
        // doc.text(`vs`, 103, 30,);
        // doc.text(`${opponent}`, 75, 40,);

        // doc.setTextColor('#000000'); // Set text color back to black (default)
        // doc.setFont('helvetica', 'normal');
        // doc.setFontSize(10);
        // doc.text(`${ageGroup} ${activeTeam}`, 103, 50);

        // doc.setFont('helvetica', 'bold');
        // doc.setFontSize(12);
        // doc.text(`Final Score: ${ourScore} : ${opponentScore}`, 88, 60);

        // Get page width for centering calculations
        const pageWidth = doc.internal.pageSize.width;

        // Set font and text color
        doc.setFont('helvetica', 'bold');
        doc.setTextColor('#000000');

        // Center school name
        doc.setFontSize(16);
        const schoolNameWidth = doc.getTextWidth(schoolName);
        const schoolNameX = (pageWidth - schoolNameWidth) / 2;
        doc.text(schoolName, schoolNameX, 20);

        // Center "vs"
        const vsWidth = doc.getTextWidth("vs");
        const vsX = (pageWidth - vsWidth) / 2;
        doc.text("vs", vsX, 30);

        // Center opponent name
        const opponentWidth = doc.getTextWidth(opponent);
        const opponentX = (pageWidth - opponentWidth) / 2;
        doc.text(opponent, opponentX, 40);

        // Set text color back to black (default)
        doc.setTextColor('#000000');
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);

        // Center ageGroup & activeTeam
        const ageGroupText = `${ageGroup} ${activeTeam}`;
        const ageGroupWidth = doc.getTextWidth(ageGroupText);
        const ageGroupX = (pageWidth - ageGroupWidth) / 2;
        doc.text(ageGroupText, ageGroupX, 50);

        // Center "Final Score"
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(12);
        const finalScoreText = `Final Score: ${ourScore} : ${opponentScore}`;
        const finalScoreWidth = doc.getTextWidth(finalScoreText);
        const finalScoreX = (pageWidth - finalScoreWidth) / 2;
        doc.text(finalScoreText, finalScoreX, 60);


        //On the Left hand side
        doc.setFont('helvetica', 'normal');
        // Add more text elements to the report
        doc.text(`Venue: ${props.venue === 'Home' ? schoolName : opponent}`, 10, 70);
        doc.text(`Kickoff time: ${kickoffTime}`, 10, 80);
        doc.text(`Man Of The Match: ${ManOfTheMatch}`, 10, 90);


        //On the right hand side
        if (props.extraStats && lineOutWin !== null && scrumWin !== null) {
            doc.setFont('helvetica', 'normal');
            // Add more text elements to the report
            doc.text(`Lineout Win: ${lineOutWin}%`, 150, 70);
            doc.text(`Scrum Win: ${scrumWin}%`, 150, 80);
            doc.text(`Knock-ons: ${knockOns}`, 150, 90);
        }


        let yPos = 105; // Starting position for the actions

        const tableData = actions.map((action) => {
            const parsedAction = parseAction(action);
            return parsedAction;
        });


        // Example table with headers and data
        const headers = ['Action Type', 'Player', 'Time'];
        const tableOptions = {
            // startY: yPos + tableData.length * 10 + 10, // Vertical position of the table
            startY: yPos,
            styles: { fontSize: 10, cellPadding: 5, valign: 'middle', halign: 'center' },
            headStyles: { fillColor: '#050505' },
        };

        // Define the footer content
        const footerContent = function (data) {
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);
            doc.text(`Copyright © Squad Control`, 85, doc.internal.pageSize.height - 10);
        };

        const tableOptionsWithFooter = {
            ...tableOptions,
            addPageContent: footerContent, // Use the footer content function
        };

        // Convert tableData to a 2D array with [actionType, player, time] as rows
        const tableRows = tableData.map(([actionType, player, time]) => [actionType, player, time]);


        // Add the table to the PDF
        doc.autoTable({
            head: [headers],
            body: tableRows, // Use the 2D array here
            ...tableOptionsWithFooter,
        });


        // doc.setFont('helvetica', 'normal');
        // doc.setFontSize(10);
        // doc.text(`Copyright © Squad Control`, 85, contentStartY + 10);


        // Save the report as a PDF file
        doc.save(`game_report_${opponent}.pdf`);
    }

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            {/* Add custom styles to the button */}
            {/* <Button
                variant='contained'
                onClick={() => generateGameReport()}
            >
                End Game
                <br />
                Generate Report
            </Button> */}

            <Button data-testid="reset" onClick={() => { handleEndGame() }} variant="contained" style={{ marginBottom: '10px' }}>
                <Clear style={{ color: 'red', marginRight: '5px', marginLeft: '-10px' }} />
                End Game
                <br />
                Generate Report
            </Button>

            {
                isManOfTheMatchModalOpen ?
                    <ManOfTheMatchModal
                        lineUp={props.lineUp}
                        team={props.team}
                        onManOfTheMatchSelected={handleManOfTheMatch}
                    />
                    :
                    <></>
            }

            <Snackbar
                open={openNotification}
                autoHideDuration={2000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? 'error' : 'success'}
                >
                    {notificationMessage}
                    <br />
                    {additionalMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default GameReportGenerator;
