/**
 * @description -  Boilerplate axios instance, used to do numerous requests to the API without needing to copy and paste the URL.
 */

import axios from 'axios';

const api = axios.create({
    // baseURL: 'http://localhost:7071/api',
    baseURL: 'https://squadcontrolbackend.azurewebsites.net/api',
    headers: {
        'Content-Type': 'application/json',
    },
});


let token
// request interceptor
api.interceptors.request.use(
    (config) => {
        token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// console.log('Token: ', token)

export default api;

