import { Button } from '@mui/material'
import React from 'react'
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import SquadControlLogoWhite from '../../assets/SquadControlWhiteRB.png'
import LogoSelector from '../../services/logoSelector';
import { useEffect } from 'react';
import { useState } from 'react';
import boksmartLogo from '../../assets/boksmart_logo-removebg-preview.png'
import saruTeamSheetImage from '../../assets/saru-team-sheet-23-player_page-01.jpg'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Menu } from "@mui/material";

function PrintTeamSelection(props) {
    const schoolName = useSelector((state) => state.user.schoolName)
    const ageGroup = useSelector((state) => state.user.profile.ageGroup)
    const [combinedData, setCombinedData] = useState([]);
    const availableTeams = useSelector((state) => state.team.availableTeams)

    const teams = props?.data?.teams?.filter(team => team.id !== 999)

    //Old UseEffect
    useEffect(() => {
        // Combine teams and players using player ids
        const newData = teams?.map((team) => ({
            ...team,
            players: team?.players?.map((playerId) =>
                props?.data?.players?.find((player) => player.id === playerId)
            ),
        }));
        setCombinedData(newData);

    }, [props]);


    useEffect(() => {
        // console.log('Combined Data: ', combinedData)
    }, [combinedData]);


    // Helper function to get the margin for each table based on its index
    function getTableMargin(index) {
        switch (index) {
            case 0:
                return { right: 140 };
            case 1:
                return { left: 78, right: 78 };
            case 2:
                return { left: 140 };
            default:
                return {};
        }
    }

    //modified new one
    // async function printSelection() {
    //     console.log('Combined: ', combinedData);
    //     var doc = new jsPDF();

    //     // Define the position and size of the logo
    //     const logoX = 10;
    //     const logoY = 10;
    //     const logoWidth = 50;
    //     const logoHeight = 50;

    //     const helperX = 78;
    //     const headerX = 73;
    //     const headerY = logoY + logoHeight + 10;

    //     const SCLogoWidth = 70;
    //     const SCLogoHeight = 80;

    //     const mySchoolLogo = await LogoSelector(schoolName);
    //     doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

    //     // Add Extra data
    //     doc.setFontSize(12);
    //     doc.text(`${schoolName}`, helperX, 30);
    //     doc.text(`u/${ageGroup}`, helperX + 20, 40);

    //     doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

    //     doc.setFontSize(15);
    //     doc.text(`Team Selection`, headerX + 10, 55);

    //     var pageNumber = doc.internal.getNumberOfPages();
    //     console.log('Page nr: ', pageNumber);


    //     let currentX = 33; // Initial X-coordinate for the first table
    //     let spaceBetweenTables = 62; // Adjust as needed
    //     let maxLastPlayerY = 0; // Track the maximum lastPlayerY

    //     // Iterate over combinedData to create a table for each team
    //     combinedData.forEach((team, index) => {
    //         // Add team name
    //         doc.setFontSize(12);
    //         doc.text(`${team.name}`, currentX - 2, 70);

    //         // Add table for the current team's players
    //         const playerTable = doc.autoTable({
    //             columns: [
    //                 { dataKey: 'Nr', header: '#' },
    //                 { dataKey: 'player', header: 'Player' },
    //             ],
    //             body: bodyRowsForTeam(team.players),
    //             startY: 75,
    //             showHead: 'firstPage',
    //             styles: { overflow: 'hidden' },
    //             margin: getTableMargin(index),
    //         });

    //         // Get the last player's y-coordinate
    //         const lastPlayerY = playerTable.lastAutoTable.finalY;

    //         // Update maxLastPlayerY if the current team has a larger value
    //         maxLastPlayerY = Math.max(maxLastPlayerY, lastPlayerY);

    //         currentX += spaceBetweenTables; // Move to the next X-coordinate for the next table
    //     });

    //     // Use the maximum lastPlayerY for setting startY for all "Impact Players" columns
    //     currentX = 33; // Reset X-coordinate for iterating again
    //     combinedData.forEach((team, index) => {
    //         // Add table for the current team's reserve players (Impact Players)
    //         doc.autoTable({
    //             columns: [
    //                 { dataKey: 'Nr', header: '#' },
    //                 { dataKey: 'player', header: 'Impact Players' },
    //             ],
    //             body: bodyRowsForImpactPlayers(team.reservePlayers),
    //             startY: maxLastPlayerY + 10, // Use the maximum lastPlayerY
    //             showHead: 'firstPage',
    //             styles: { overflow: 'hidden' },
    //             margin: getTableMargin(index),
    //         });

    //         currentX += spaceBetweenTables; // Move to the next X-coordinate for the next table
    //     });

    //     doc.setFont('helvetica', 'normal');
    //     doc.setFontSize(10);
    //     doc.text(`Copyright © Squad Control`, headerX + 10, doc.internal.pageSize.height - 10);


    //     const date = new Date()

    //     doc.save(`Team Selection ${date.toDateString()}.pdf`);
    // }

    /////////////////////OLD WORKING
    // async function printSelection(selectedTeams) {
    //     console.log('Combined: ', combinedData);
    //     console.log('selectedTeams: ', selectedTeams);
    //     var doc = new jsPDF();

    //     // Define the position and size of the logo
    //     const logoX = 10;
    //     const logoY = 10;
    //     const logoWidth = 50;
    //     const logoHeight = 50;

    //     const helperX = 78;
    //     const headerX = 73;
    //     const headerY = logoY + logoHeight + 10;

    //     const SCLogoWidth = 70;
    //     const SCLogoHeight = 80;

    //     const mySchoolLogo = await LogoSelector(schoolName);
    //     doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

    //     // // Add Extra data
    //     // doc.setFontSize(12);
    //     // // doc.text(`${schoolName}`, helperX, 30); // OLD

    //     // // Define the width of the section where the school name should be centered
    //     // const pageWidth = doc.internal.pageSize.width; // Get the PDF page width
    //     // const schoolNameWidth = doc.getTextWidth(schoolName); // Get text width dynamically

    //     // // Calculate the x position dynamically for centering
    //     // const textX = (pageWidth - schoolNameWidth) / 2;
    //     // doc.setFontSize(15);
    //     // // Print the school name centered
    //     // doc.text(schoolName, textX, 30);
    //     // doc.setFontSize(12);

    //     // /////////
    //     // doc.text(`u/${ageGroup}`, helperX + 20, 40);

    //     // doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

    //     // doc.setFontSize(15);
    //     // doc.text(`Team Selection`, headerX + 10, 55);


    //     //////NEW CENTERING FOR ALL:
    //     // Get the page width for centering calculations
    //     const pageWidth = doc.internal.pageSize.width;

    //     // Centering the school name
    //     doc.setFontSize(15);
    //     const schoolNameWidth = doc.getTextWidth(schoolName);
    //     const schoolNameX = (pageWidth - schoolNameWidth) / 2;
    //     doc.text(schoolName, schoolNameX, 30);

    //     // Centering the age group
    //     doc.setFontSize(12);
    //     const ageGroupText = `u/${ageGroup}`;
    //     const ageGroupWidth = doc.getTextWidth(ageGroupText);
    //     const ageGroupX = (pageWidth - ageGroupWidth) / 2;
    //     doc.text(ageGroupText, ageGroupX, 40);

    //     // Adding SquadControl logo
    //     doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

    //     // Centering "Team Selection" heading
    //     doc.setFontSize(15);
    //     const teamSelectionText = "Team Selection";
    //     const teamSelectionWidth = doc.getTextWidth(teamSelectionText);
    //     const teamSelectionX = (pageWidth - teamSelectionWidth) / 2;
    //     doc.text(teamSelectionText, teamSelectionX, 55);


    //     let currentX = 33; // Initial X-coordinate for the first table
    //     let spaceBetweenTables = 62; // Adjust as needed
    //     let maxLastPlayerY = 0; // Track the maximum lastPlayerY

    //     let currentTeam =
    //         // Iterate over combinedData to create a table for each team
    //         combinedData.forEach((team, index) => {
    //             if (index > 2) {
    //                 doc.addPage(); // If the index is more than 2, put the team on a new page
    //                 currentX = 33; // Reset X-coordinate for the first table on new page

    //             }

    //             // Add team name
    //             doc.setFontSize(12);
    //             doc.text(`${team.name}`, currentX - 2, 70);

    //             // Add table for the current team's players
    //             const playerTable = doc.autoTable({
    //                 columns: [
    //                     { dataKey: 'Nr', header: '#' },
    //                     { dataKey: 'player', header: 'Player' },
    //                 ],
    //                 body: bodyRowsForTeam(team.players),
    //                 startY: 75,
    //                 showHead: 'firstPage',
    //                 styles: { overflow: 'hidden' },
    //                 margin: getTableMargin(index),
    //             });

    //             // Get the last player's y-coordinate
    //             const lastPlayerY = playerTable.lastAutoTable.finalY;

    //             // Update maxLastPlayerY if the current team has a larger value
    //             maxLastPlayerY = Math.max(maxLastPlayerY, lastPlayerY);

    //             // Move to the next X-coordinate for the next table
    //             currentX += spaceBetweenTables;
    //         });

    //     // Use the maximum lastPlayerY for setting startY for all "Impact Players" columns
    //     currentX = 33; // Reset X-coordinate for iterating again
    //     combinedData.forEach((team, index) => {
    //         // Add table for the current team's reserve players (Impact Players)
    //         doc.autoTable({
    //             columns: [
    //                 { dataKey: 'Nr', header: '#' },
    //                 { dataKey: 'player', header: 'Impact Players' },
    //             ],
    //             body: bodyRowsForImpactPlayers(team.reservePlayers),
    //             startY: maxLastPlayerY + 10, // Use the maximum lastPlayerY
    //             showHead: 'firstPage',
    //             styles: { overflow: 'hidden' },
    //             margin: getTableMargin(index),
    //         });

    //         currentX += spaceBetweenTables; // Move to the next X-coordinate for the next table
    //     });

    //     const date = new Date();
    //     // Save the PDF
    //     // doc.save(`Team Selection.pdf`);
    //     doc.save(`Team Selection ${date.toDateString()}.pdf`);
    // }



    // // Helper function to generate body rows for a team
    // function bodyRowsForTeam(players) {
    //     return players?.map((player, playerIndex) => ({
    //         Nr: playerIndex + 1,
    //         player: `${player.firstName} ${player.lastName}`,
    //     }));
    // }

    // // Helper function to generate body rows for "Impact Players" column
    // // function bodyRowsForImpactPlayers(players) {
    // //     // Start numbering from 16
    // //     let startingNumber = 16;
    // //     return players.map((player, playerIndex) => ({
    // //         Nr: startingNumber + playerIndex,
    // //         player: `${player.firstName} ${player.lastName}`,
    // //     }));
    // // }

    // //New one where 17 is first then 16
    // // Helper function to generate body rows for "Impact Players" column
    // function bodyRowsForImpactPlayers(players) {
    //     // Start numbering from 17 for the first player
    //     let startingNumber = 17;

    //     // Define the array to hold the sequence of numbers
    //     let numbers = [startingNumber, 16];

    //     // Populate the array with the numbers 18 to 23
    //     for (let i = 18; i <= 23; i++) {
    //         numbers.push(i);
    //     }

    //     // Return the mapped array with player names
    //     return players?.map((player, playerIndex) => ({
    //         Nr: numbers[playerIndex], // Get the appropriate number for each player
    //         player: `${player.firstName} ${player.lastName}`,
    //     }));
    // }
    /////////////////////OLD WORKING


    async function printNewSelection(selectedTeams) {
        // console.log('Combined: ', combinedData);
        // console.log('selectedTeams: ', selectedTeams);

        var doc = new jsPDF();


        // Filter only selected teams
        const teamsToPrint = selectedTeams.includes("All") ? combinedData : combinedData.filter(team => selectedTeams.includes(team.name));


        if (teamsToPrint.length === 0) {
            console.log("No teams selected for printing.");
            return;
        }

        // Define constants for layout
        const pageWidth = doc.internal.pageSize.width;
        const defaultTableWidth = 80; // Width remains unchanged for 3 teams
        const reducedTableWidth = 60; // Adjusted width for 1 or 2 teams
        const logoX = 10, logoY = 10, logoWidth = 50, logoHeight = 50;
        const SCLogoWidth = 70, SCLogoHeight = 80;

        const mySchoolLogo = await LogoSelector(schoolName);
        doc.addImage(mySchoolLogo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Centering the school name
        doc.setFontSize(15);
        const schoolNameWidth = doc.getTextWidth(schoolName);
        doc.text(schoolName, (pageWidth - schoolNameWidth) / 2, 30);

        // Centering the age group
        doc.setFontSize(12);
        const ageGroupText = `U/${ageGroup}`;
        doc.text(ageGroupText, (pageWidth - doc.getTextWidth(ageGroupText)) / 2, 40);

        // Adding SquadControl logo
        doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

        // Centering "Team Selection" heading
        doc.setFontSize(15);
        const teamSelectionText = "Team Selection";
        doc.text(teamSelectionText, (pageWidth - doc.getTextWidth(teamSelectionText)) / 2, 55);

        let startX, spaceBetweenTables, tableWidth;

        if (teamsToPrint.length === 1) {
            startX = (pageWidth - reducedTableWidth) / 2; // Single team centered
            spaceBetweenTables = 0;
            tableWidth = reducedTableWidth;
        } else if (teamsToPrint.length === 2) {
            startX = 33; // Two teams get even spacing
            spaceBetweenTables = 80;
            tableWidth = reducedTableWidth;
        } else {
            startX = 33; // **For 3 teams, keep the original layout**
            spaceBetweenTables = 62;
            tableWidth = defaultTableWidth;

            // currentX = 33; // Initial X-coordinate for the first table
            // spaceBetweenTables = 62; // Adjust as needed
            // maxLastPlayerY = 0; // Track the maximum lastPlayerY
        }

        let maxLastPlayerY = 0;

        if (teamsToPrint.length >= 3) {
            teamsToPrint.forEach((team, index) => {
                if (index > 2) {
                    doc.addPage();
                    startX = 33;
                }

                doc.setFontSize(12);
                doc.text(`${team.name}`, startX - 2, 70);

                const playerTable = doc.autoTable({
                    columns: [
                        { dataKey: 'Nr', header: '#' },
                        { dataKey: 'player', header: 'Player' },
                    ],
                    body: bodyRowsForTeam(team.players),
                    startY: 75,
                    showHead: 'firstPage',
                    styles: { overflow: 'hidden' },
                    margin: getTableMargin(index),
                });

                const lastPlayerY = playerTable.lastAutoTable.finalY;
                maxLastPlayerY = Math.max(maxLastPlayerY, lastPlayerY);

                startX += spaceBetweenTables;
            });

            startX = 33;
            teamsToPrint.forEach((team, index) => {
                doc.autoTable({
                    columns: [
                        { dataKey: 'Nr', header: '#' },
                        { dataKey: 'player', header: 'Impact Players' },
                    ],
                    body: bodyRowsForImpactPlayers(team.reservePlayers),
                    startY: maxLastPlayerY + 10,
                    showHead: 'firstPage',
                    styles: { overflow: 'hidden' },
                    margin: getTableMargin(index),
                });

                startX += spaceBetweenTables;
            });
        }
        else {
            printTeams(doc, teamsToPrint, startX, spaceBetweenTables, tableWidth);
        }

        // Save PDF with date
        const date = new Date();
        doc.save(`Team Selection ${date.toDateString()}.pdf`);
    }

    // Function to print teams with autoTable
    function printTeams(doc, teams, startX, spaceBetweenTables, tableWidth) {
        let currentX = startX;
        let maxLastPlayerY = 0;

        teams.forEach((team, index) => {
            doc.setFontSize(12);
            const teamNameWidth = doc.getTextWidth(team.name);
            doc.text(team.name, currentX + (tableWidth / 2) - (teamNameWidth / 2), 70); // Centered above table

            const playerTable = doc.autoTable({
                columns: [{ dataKey: 'Nr', header: '#' }, { dataKey: 'player', header: 'Player' }],
                body: bodyRowsForTeam(team.players),
                startY: 75,
                showHead: 'firstPage',
                styles: { overflow: 'hidden' },
                margin: { left: currentX },
                tableWidth: tableWidth,
            });

            maxLastPlayerY = Math.max(maxLastPlayerY, playerTable.lastAutoTable.finalY);
            currentX += spaceBetweenTables;
        });

        // Print "Impact Players" table
        currentX = startX;
        teams.forEach((team, index) => {
            doc.autoTable({
                columns: [{ dataKey: 'Nr', header: '#' }, { dataKey: 'player', header: 'Impact Players' }],
                body: bodyRowsForImpactPlayers(team.reservePlayers),
                startY: maxLastPlayerY + 10,
                showHead: 'firstPage',
                styles: { overflow: 'hidden' },
                margin: { left: currentX },
                tableWidth: tableWidth,
            });

            currentX += spaceBetweenTables;
        });

        return maxLastPlayerY;
    }

    // Helper functions remain unchanged
    function bodyRowsForTeam(players) {
        return players?.map((player, index) => ({
            Nr: index + 1,
            player: `${player.firstName} ${player.lastName}`,
        }));
    }

    function bodyRowsForImpactPlayers(players) {
        let numbers = [17, 16, ...Array.from({ length: 6 }, (_, i) => i + 18)];
        return players?.map((player, index) => ({
            Nr: numbers[index],
            player: `${player.firstName} ${player.lastName}`,
        }));
    }




    const formatDOB = (dob) => {
        if (!dob) return "--"; // Handle empty DOBs gracefully

        const parts = dob.split("/"); // Assuming input format is "yyyy/mm/dd" or similar
        if (parts.length === 3) {
            const year = parts[0]; // Example: "2005"
            const splitYear = `${year.slice(0, 2)}  ${year.slice(2)}`; // "20 05"

            return `${parts[2]}    ${parts[1]}      ${splitYear}`; // DO NOT CHANGE SPACING
        }
        return dob; // Return as-is if unexpected format
    };


    // Define exact X & Y positions for each player slot
    const positionCoordinates = [
        { x: 60, y: 107, pos: "15 Full Back" },
        { x: 60, y: 113, pos: "14 Right Wing" },
        { x: 60, y: 119, pos: "13 Right Centre" },
        { x: 60, y: 124, pos: "12 Left Centre" },
        { x: 60, y: 130, pos: "11 Left Wing" },
        { x: 60, y: 136, pos: "10 Fly Half" },
        { x: 60, y: 142, pos: "9 Scrum Half" },
        { x: 60, y: 148, pos: "8 Number Eight" },
        { x: 60, y: 153, pos: "7 Right Flanker" },
        { x: 60, y: 159, pos: "6 Left Flanker" },
        { x: 60, y: 165, pos: "5 Right Lock" },
        { x: 60, y: 171, pos: "4 Left Lock" },
        { x: 60, y: 177, pos: "3 Tight Head Prop" },
        { x: 60, y: 183, pos: "2 Hooker" },
        { x: 60, y: 188, pos: "1 Loose Head Prop" }
    ];

    // Adjusted reserve spacing (moved down by 15 units)
    const reserveCoordinates = [
        { x: 60, y: 202, pos: "16 Hooker" },
        { x: 60, y: 208, pos: "17 Prop" },
        { x: 60, y: 214, pos: "18 Prop" },
        { x: 60, y: 220, pos: "19 Replacement" },
        { x: 60, y: 226, pos: "20 Replacement" },
        { x: 60, y: 232, pos: "21 Replacement" },
        { x: 60, y: 238, pos: "22 Replacement" },
        { x: 60, y: 243, pos: "23 Replacement" }
    ];



    const generateTeamSheet = () => {

        if (combinedData) {
            combinedData.forEach((team) => {
                const doc = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });

                // Add the SARU Team Sheet as the background
                doc.addImage(saruTeamSheetImage, "PNG", 0, 0, 210, 297); // Full A4 size

                // Set font properties
                doc.setFont("helvetica", "normal");
                doc.setFontSize(10);

                // Insert age group at the correct location
                doc.text(`u/${ageGroup} - ${team.name}`, 100, 41); // Centered on the form
                doc.setFontSize(8);
                // Insert team name at the correct location
                doc.text(schoolName, 60, 47); // Centered on the form

                // // Insert opposition name at the correct location
                // doc.text(ageGroup, 80, 40); // Centered on the form

                doc.setFontSize(8);
                // Insert players into their assigned positions
                // team.players.forEach((player, index) => {
                //     if (index < positionCoordinates.length) {
                //         const { x, y } = positionCoordinates[index];

                //         // Insert Initials & Surname
                //         doc.text(`${player.firstName.charAt(0)}. ${player.lastName}`, x, y);

                //         // Insert Full Name
                //         doc.text(`${player.firstName}`, x + 35, y);

                //         const formattedDOB = formatDOB(player.dateOfBirth);
                //         doc.text(formattedDOB, x + 105, y - 0.5);
                //     }
                // });

                // Insert players into their assigned positions (Reversed Order)
                team.players.forEach((player, index) => {
                    // Reverse index so the first player is placed at the last position
                    const reverseIndex = positionCoordinates.length - 1 - index;

                    if (reverseIndex >= 0) {
                        const { x, y } = positionCoordinates[reverseIndex];

                        // Insert Initials & Surname
                        doc.text(`${player.firstName.charAt(0)}. ${player.lastName}`, x, y);

                        // Insert Full Name
                        doc.text(`${player.firstName}`, x + 35, y);

                        const formattedDOB = formatDOB(player.dateOfBirth);
                        doc.text(formattedDOB, x + 105, y - 0.5);
                    }
                });


                // // Insert reserve players at the bottom section
                // team.reservePlayers.forEach((player, index) => {
                //     if (index < reserveCoordinates.length) {
                //         const { x, y } = reserveCoordinates[index];

                //         // Insert Initials & Surname
                //         doc.text(`${player.firstName.charAt(0)}. ${player.lastName}`, x, y);

                //         // Insert Full Name
                //         doc.text(`${player.firstName}`, x + 35, y);

                //         const formattedDOB = formatDOB(player.dateOfBirth);
                //         doc.text(formattedDOB, x + 105, y - 0.5);
                //     }
                // });

                // Insert reserve players at the bottom section
                team.reservePlayers.forEach((player, index) => {
                    let adjustedIndex = index; // Default index mapping

                    // Ensure there are at least 3 reserve players before reordering
                    if (team.reservePlayers.length >= 3) {
                        if (index === 0) {
                            adjustedIndex = 2; // Move the 3rd player (index 2) to 16 Hooker
                        } else if (index === 2) {
                            adjustedIndex = 0; // Move the first player (index 0) to where 3rd player was
                        }
                    }

                    if (adjustedIndex < reserveCoordinates.length) {
                        const { x, y } = reserveCoordinates[index]; // Keep normal index order for placement

                        // Insert Initials & Surname
                        doc.text(`${team.reservePlayers[adjustedIndex].firstName.charAt(0)}. ${team.reservePlayers[adjustedIndex].lastName}`, x, y);

                        // Insert Full Name
                        doc.text(`${team.reservePlayers[adjustedIndex].firstName}`, x + 35, y);

                        // Format and insert DOB
                        const formattedDOB = formatDOB(team.reservePlayers[adjustedIndex].dateOfBirth);
                        doc.text(formattedDOB, x + 105, y - 0.5);
                    }
                });


                // Save PDF with team name
                doc.save(`${team.name}_Team_Sheet.pdf`);
            });
        } else {
            console.log("Empty");
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (teamName) => {
        setSelectedTeams((prev) =>
            prev.includes(teamName)
                ? prev.filter((name) => name !== teamName)
                : [...prev, teamName]
        );
    };

    function handleDone() {
        console.log('Selected Teams: ', selectedTeams)
        printNewSelection(selectedTeams)
        setAnchorEl(!anchorEl)
    }

    return (
        // <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'starts' }}>
        //     <Button variant='contained' onClick={() => { printSelection(); }}>
        //         Print Teams
        //     </Button>

        //     <FormControl fullWidth>
        //         <InputLabel id="demo-simple-select-label">Print Teams</InputLabel>
        //         <Select
        //             labelId="demo-simple-select-label"
        //             id="demo-simple-select"
        //             value={10}
        //             label="Print Teams"

        //         >
        //             <MenuItem value={10}>All</MenuItem>
        //             {
        //                 availableTeams?.map((team) => {
        //                     return (
        //                         <MenuItem value={team.name}>{team.name}</MenuItem>
        //                     )
        //                 })
        //             }
        //         </Select>
        //     </FormControl>

        //     <Button variant='contained' onClick={() => { generateTeamSheet(); }} style={{ marginLeft: '5px' }}>
        //         Print BokSmart Lists
        //     </Button>
        // </div >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {/* <Button variant="contained" onClick={() => { printSelection(); }}>
                Print Teams
            </Button> */}

            <Button
                variant="outlined"
                onClick={handleClick}
                style={{ marginLeft: '5px' }}
            >
                Print Teams ▼
            </Button>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleSelect("All")}>
                    {selectedTeams.includes("All") ? "✔ " : ""} All
                </MenuItem>
                {availableTeams?.map((team) => (
                    <MenuItem key={team.name} onClick={() => handleSelect(team.name)}>
                        {selectedTeams.includes(team.name) ? "✔ " : ""} {team.name}
                    </MenuItem>
                ))}
                <Button onClick={() => { handleDone() }} style={{ marginLeft: '5px' }}>Print</Button>
            </Menu>

            <Button variant="contained" onClick={() => { generateTeamSheet(); }} style={{ marginLeft: '5px' }}>
                Print BokSmart Lists
            </Button>
        </div>

    )
}

export default PrintTeamSelection
