import { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { Button, Typography, Box, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert, List, ListItem, ListItemButton } from '@mui/material';
import { resourceRequests } from '../../services/api-requests';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import Loader from '../../layouts/loader';
import { useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';


export default function UserGuides() {

    const [numPages, setNumPages] = useState();
    const [pdf, setPdf] = useState();
    const [show, setShow] = useState(false);
    const [fileInfo, setFileInfo] = useState();
    const [fileName, setFileName] = useState();
    const [guides, setGuides] = useState([]);
    const [fileId, setFileId] = useState([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [Message, setMessage] = useState('');
    const [deleted, setDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [fileUploadLoading, setIsFileUploadLoading] = useState(false)
    const [wholeSchool, setWholeSchool] = useState(false)
    const [onlyMyAgeGroup, setOnlyMyAgeGroup] = useState(false)
    const [isError, setIsError] = useState(false)

    const ageGroup = parseInt(useSelector((state) => parseInt(state.user.profile.ageGroup)))
    const schoolId = parseInt(useSelector((state) => parseInt(state.user.profile.school)))
    const admin = useSelector((state) => state.user.admin)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate()

    useEffect(() => {
        fetchFiles()
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    };

    const handleCloseSnackbar = (event, reason) => {
        setOpenSnackbar(false);
        fetchFiles();
    };


    const handleFileInputChange = (event) => {
        const file = event?.target?.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Get the Base64 string after the comma
            setFileInfo(base64String);
        };
        // Read the file as a data URL (which includes Base64 encoding)
        reader.readAsDataURL(file);
        setPdf(file);
    };


    async function uploadFile() {

        if (fileInfo) {

            const uniqueId = Math.floor(Math.random() * 1000000);

            const lastItem = guides[guides.length - 1];

            if (fileInfo && fileName) {

                const newFile = {
                    // id: lastItem.id + 1,
                    Id: 1,
                    UserGuideName: fileName,
                    GuideData: fileInfo,
                    UserGuideType: "pdf"
                };

                // console.log('Going to be sending over this file: ', newFile)
                try {
                    setIsFileUploadLoading(true)
                    const response = await resourceRequests.uploadNewUserGuide(newFile);
                    // const response = 201
                    console.log(response.status)

                    if (response.status === 200) {
                        handleClose();
                        setMessage("Uploaded")
                        setDeleted(false);
                        setIsError(false)
                        setOpenSnackbar(true)

                    } else {
                        handleClose();
                        setMessage("Something went wrong")
                        setDeleted(true);
                        setIsError(true)
                        setOpenSnackbar(true)
                    }
                } catch (error) {
                    console.error('Error ', error)
                    handleClose();
                    setMessage("Something went wrong")
                    setDeleted(true);
                    setIsError(true)
                    setOpenSnackbar(true)
                }
                finally {
                    setIsFileUploadLoading(false)
                }

            }
            else {
                console.error("NO FILE");
                alert('Please ensure you have selected a file')
            }
        } else {
            setDeleted(true)
            setMessage('Please fill out all fields before submitting')
            setOpenSnackbar(true)
        }

    };

    // file download
    const handleDownload = () => {
        saveAs(pdf, 'downloaded_userGuide.pdf');
    };

    //delete file 
    async function deleteFile() {
        setIsLoading(true)

        try {

            const response = await resourceRequests.deleteUserGuides(fileId);

            if (response.status === 200) {
                fetchFiles();

                setPdf();
                setMessage('User Guide successfully deleted');
                setOpenSnackbar(true);
            } else {
                setMessage('File could not be deleted');
                setDeleted(true);
                setOpenSnackbar(true);
            }
        }
        catch (error) {
            console.error("something went wrong")
        }
        finally {
            setIsLoading(true)
        }
    }

    const fetchFiles = async () => {

        const tempGuides = [];

        try {
            setIsLoading(true)
            const response = await resourceRequests.getUserGuides();

            if (response.status === 200) {

                response?.data?.forEach((file) => {

                    const fileObj = {
                        Id: file.id,
                        UserGuideName: file.userGuideName,
                        GuideURL: file.guideURL ? file.guideURL : "",
                        UserGuideType: file.userGuideType,

                    };
                    tempGuides.push(fileObj);
                });

                setGuides(tempGuides);


            } else {
                console.error("not working")
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };


    const loadPdf = (Id) => {
        setFileId(Id);

        var loadedFile = guides.find(file => file.Id === Id);

        if (!loadedFile) {
            setMessage('File could not be loaded');
            setDeleted(true);
            setOpenSnackbar(true);
        } else {
            // Use the fileUrl instead of fileData
            var selectedFile = loadedFile.GuideURL; // Adjusted to use the file URL directly
            setPdf(selectedFile);
        }
    };


    function navigateBack() {
        navigate('/Resource');
    }



    return (
        // <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 2 }}>
        //     {/* Title */}
        //     <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} container alignItems="center">
        //         <Typography component='h5' variant='h6' sx={{ alignItems: 'center', justifyContent: 'center', marginLeft: '-20px', marginTop: '-30px' }}>
        //             User Guides
        //         </Typography>
        //     </Grid>

        //     {/* Buttons */}
        //     <Grid container spacing={2} sx={{ marginTop: 1 }}>
        //         <Grid item xs={3} md={2}>
        //             <Button variant='outlined' startIcon={<ArrowBackIcon />} onClick={navigateBack} fullWidth>
        //                 Back
        //             </Button>
        //         </Grid>
        //         <Grid item xs={9} md={10}>
        //             <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        //                 {admin && (
        //                     <>
        //                         <Button startIcon={<UploadIcon />} sx={{ marginRight: 1 }} variant="contained" onClick={handleShow}>
        //                             New User guides
        //                         </Button>
        //                         <Button startIcon={<DeleteIcon />} variant="contained" onClick={deleteFile}>
        //                             Delete
        //                         </Button>
        //                     </>
        //                 )}
        //                 <Button startIcon={<DownloadIcon />} sx={{ marginLeft: 1 }} variant="contained" onClick={handleDownload}>
        //                     Download
        //                 </Button>
        //             </Box>
        //         </Grid>
        //     </Grid>

        //     {/* PDF Viewer and List */}
        //     <Grid container spacing={2} sx={{ marginTop: 2 }}>

        //         <Grid item xs={12} md={2}>
        //             {isLoading ? (
        //                 <Loader />
        //             ) : (
        //                 isError ? (
        //                     <p>Could not retrieve guides</p>
        //                 ) : (
        //                     guides.length === 0 ? (
        //                         <p>No guides found</p>
        //                     ) : (
        //                         <List>
        //                             {guides.map((file) =>

        //                             (
        //                                 <ListItem button key={file.Id} onClick={() => loadPdf(file.Id)}>
        //                                     {file.UserGuideName} {file.GuideURL ? "" : "(Unavailable)"}
        //                                 </ListItem>
        //                             )
        //                             )}
        //                         </List>
        //                     )
        //                 )
        //             )}
        //         </Grid>

        //         {/* PDF viewer section */}
        //         <Grid item xs={12} md={8}>
        //             <Box sx={{ display: 'flex', justifyContent: 'center', width: '700px', height: '600px' }}>
        //                 <div className='pdf-div'>
        //                     <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        //                         {Array.from({ length: numPages }, (_, i) => i + 1).map((page) => (
        //                             <Page key={page} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
        //                         ))}
        //                     </Document>
        //                 </div>
        //             </Box>
        //         </Grid>
        //     </Grid>

        //     {/* Modal for uploading new files */}
        //     <Dialog open={show} onClose={handleClose}>
        //         <DialogTitle>Upload new file</DialogTitle>
        //         <DialogContent>
        //             <Box component="form" noValidate autoComplete="off">
        //                 <TextField
        //                     fullWidth
        //                     margin="dense"
        //                     label="Select file to upload"
        //                     type="file"
        //                     onChange={handleFileInputChange}
        //                 />
        //                 <TextField
        //                     fullWidth
        //                     margin="dense"
        //                     label="File Name"
        //                     onChange={(e) => setFileName(e.target.value)}
        //                 />
        //             </Box>
        //         </DialogContent>
        //         <DialogActions>
        //             <Button variant="outlined" onClick={handleClose}>
        //                 Close
        //             </Button>
        //             <Button variant="contained" onClick={uploadFile}>
        //                 Upload File
        //             </Button>
        //         </DialogActions>
        //     </Dialog>

        //     {/* Snackbar for notifications */}
        //     <Snackbar
        //         open={openSnackbar}
        //         autoHideDuration={6000}
        //         onClose={handleCloseSnackbar}
        //         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        //     >
        //         <MuiAlert
        //             elevation={6}
        //             variant="filled"
        //             onClose={handleCloseSnackbar}
        //             severity={deleted ? 'error' : 'success'}
        //         >
        //             {Message}
        //         </MuiAlert>
        //     </Snackbar>
        // </Box>


        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3, gap: 3 }}>
            {/* Title */}
            <Typography component="h5" variant="h6" sx={{ textAlign: 'center', marginTop: '-20px' }}>
                User Guides
            </Typography>

            {/* Buttons */}
            <Grid container spacing={2} sx={{ maxWidth: '900px', alignItems: 'center' }}>
                <Grid item xs={12} md={2}>
                    <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={navigateBack} fullWidth>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        {admin && (
                            <>
                                <Button startIcon={<UploadIcon />} variant="contained" onClick={handleShow}>
                                    New User Guide
                                </Button>
                                <Button startIcon={<DeleteIcon />} variant="contained" color="error" onClick={deleteFile}>
                                    Delete
                                </Button>
                            </>
                        )}
                        <Button startIcon={<DownloadIcon />} variant="contained" onClick={handleDownload}>
                            Download
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* PDF Viewer and List */}
            <Grid container spacing={3} sx={{ maxWidth: '1200px' }}>
                {/* List Section */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2, boxShadow: 1 }}>
                        {isLoading ? (
                            <Loader />
                        ) : isError ? (
                            <Typography color="error">Could not retrieve guides</Typography>
                        ) : guides.length === 0 ? (
                            <Typography>No guides found</Typography>
                        ) : (
                            <List>
                                {guides.map((file) => (
                                    <ListItem
                                        button
                                        key={file.Id}
                                        onClick={() => loadPdf(file.Id)}
                                        sx={{
                                            borderRadius: 1,
                                            '&:hover': { backgroundColor: '#e3f2fd' },
                                        }}
                                    >
                                        {file.UserGuideName} {file.GuideURL ? '' : '(Unavailable)'}
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                </Grid>

                {/* PDF Viewer */}
                <Grid item xs={12} md={8}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '700px',
                            backgroundColor: '#f4f4f4',
                            borderRadius: 2,
                            boxShadow: 2,

                        }}
                    >


                        <div className='pdf-div'>
                            <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from({ length: numPages }, (_, i) => i + 1).map((page) => (
                                    <Page key={page} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} />
                                ))}
                            </Document>
                        </div>


                    </Box>
                </Grid>
            </Grid>

            {/* Modal for Uploading New Files */}
            <Dialog open={show} onClose={handleClose}>
                <DialogTitle>Upload New File</DialogTitle>
                <DialogContent>
                    <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Select file to upload"
                            type="file"
                            onChange={handleFileInputChange}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            label="File Name"
                            onChange={(e) => setFileName(e.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={uploadFile}>
                        Upload File
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={deleted ? 'error' : 'success'}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>
        </Box>

    );
}
