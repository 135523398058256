import * as React from 'react'
import { useEffect } from 'react';
import { Button, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { managerRequests, devRequests } from '../../services/api-requests';
import Loader from '../loader';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, useMediaQuery } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function AdminTeams() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSchoolLoading, setisSchoolLoading] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [Message, setMessage] = React.useState('');
    const [error, setError] = React.useState(false);
    const [allCoaches, setAllCoaches] = React.useState([]);
    const [coachesInSchool, setCoachesInSchool] = React.useState(0);
    const [handleDeletedClicked, setHandleDeletedClicked] = React.useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [coachToBeDeleted, setCoachToBeDeleted] = React.useState('');
    const [selectedSchool, setSelectedSchool] = React.useState('');
    const [receivedSchools, setReceivedSchools] = React.useState([]);
    const [schoolLinks, setSchoolLinks] = React.useState([{
        ageGroup: null,
        googleFormLink: '',
        googleSheetLink: '',
        email: ''
    }]);
    const [open, setOpen] = React.useState();
    const handleOpen = () => setConfirmationModalOpen(true);
    const handleClose = () => setConfirmationModalOpen(false);
    const [selectedLink, setSelectedLink] = React.useState(null);
    const [selectedSchoolLink, setSelectedSchoolLinks] = React.useState([]);
    const [schoolIWantId, setSchoolIWantId] = React.useState(0);


    // const schoolLinks = [
    //     { ageGroup: 14, googleFormLink: 'https://example.com/form14', googleSheetLink: 'https://example.com/sheet14' },
    //     { ageGroup: 15, googleFormLink: 'https://example.com/form15', googleSheetLink: 'https://example.com/sheet15' },
    //     { ageGroup: 16, googleFormLink: 'https://example.com/form16', googleSheetLink: 'https://example.com/sheet16' },
    //     { ageGroup: 19, googleFormLink: 'https://example.com/form19', googleSheetLink: 'https://example.com/sheet19' }
    // ]
    const [ageGroupData, setAgeGroupData] = React.useState({
        ageGroup: null,
        googleFormLink: '',
        googleSheetLink: '',
        email: ''
    });


    // const ConfirmationModal = ({ open, onClose }) => {


    //     const isMobile = useMediaQuery("(max-width:600px)")

    //     const handleNo = () => {
    //         console.log('Handle No clicked');
    //         onClose();
    //     };

    //     const handleYes = () => {
    //         console.log('Handle Yes clicked');
    //         handleYesClick();
    //         onClose();
    //     };

    //     return (
    //         <div >
    //             <Dialog open={confirmationModalOpen} onClose={handleClose} >
    //                 <DialogTitle>Edit Links for Age Group {selectedLink.ageGroup}</DialogTitle>
    //                 <DialogContent>
    //                     <div>
    //                         <TextField
    //                             label="Google Form Link"
    //                             fullWidth
    //                             variant="outlined"
    //                             value={selectedLink.googleFormLink}
    //                             onChange={(e) => handleChange(e, 'googleFormLink')}
    //                             margin="normal"
    //                             InputProps={{
    //                                 endAdornment: (
    //                                     <IconButton onClick={() => handleClear('googleFormLink')} edge="end">
    //                                         <CloseIcon style={{ color: 'red' }} />
    //                                     </IconButton>
    //                                 )
    //                             }}
    //                         />
    //                     </div>
    //                     <div>
    //                         <TextField
    //                             label="Google Sheet Link"
    //                             fullWidth
    //                             variant="outlined"
    //                             value={selectedLink.googleSheetLink}
    //                             onChange={(e) => handleChange(e, 'googleSheetLink')}
    //                             margin="normal"
    //                             InputProps={{
    //                                 endAdornment: (
    //                                     <IconButton onClick={() => handleClear('googleSheetLink')} edge="end" >
    //                                         <CloseIcon style={{ color: 'red' }} />
    //                                     </IconButton>
    //                                 )
    //                             }}
    //                         />
    //                     </div>
    //                     <div>
    //                         <TextField
    //                             label="Owner of Links"
    //                             fullWidth
    //                             variant="outlined"
    //                             value={selectedLink.coachEmail}
    //                             onChange={(e) => handleChange(e, 'email')}
    //                             margin="normal"
    //                             InputProps={{
    //                                 endAdornment: (
    //                                     <IconButton onClick={() => handleClear('email')} edge="end" >
    //                                         <CloseIcon style={{ color: 'red' }} />
    //                                     </IconButton>
    //                                 )
    //                             }}
    //                         />
    //                     </div>
    //                 </DialogContent>
    //                 <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
    //                     <Button onClick={handleClose} style={{ color: "red" }}>
    //                         Cancel
    //                     </Button>
    //                     <Button onClick={handleSave} variant='contained'>
    //                         Save
    //                     </Button>
    //                 </DialogActions>
    //             </Dialog>
    //         </div >


    //     );
    // };


    const ConfirmationModal = ({ open, onClose, selectedLink, onSave }) => {
        const [localLink, setLocalLink] = React.useState(selectedLink || {});
        const isMobile = useMediaQuery("(max-width:600px)")

        React.useEffect(() => {
            setLocalLink(selectedLink || {});
        }, [selectedLink]);

        const handleChange = (e, field) => {
            setLocalLink((prev) => ({
                ...prev,
                [field]: e.target.value
            }));
        };

        const handleClear = (field) => {
            setLocalLink((prev) => ({
                ...prev,
                [field]: ''
            }));
        };

        const handleSave = () => {
            onSave(localLink);
            onClose();
        };

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Edit Links for Age Group {localLink.ageGroup}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Google Form Link"
                        fullWidth
                        variant="outlined"
                        value={localLink.googleFormLink || ''}
                        onChange={(e) => handleChange(e, 'googleFormLink')}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => handleClear('googleFormLink')} edge="end">
                                    <CloseIcon style={{ color: 'red' }} />
                                </IconButton>
                            )
                        }}
                    />
                    <TextField
                        label="Google Sheet Link"
                        fullWidth
                        variant="outlined"
                        value={localLink.googleSheetLink || ''}
                        onChange={(e) => handleChange(e, 'googleSheetLink')}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => handleClear('googleSheetLink')} edge="end">
                                    <CloseIcon style={{ color: 'red' }} />
                                </IconButton>
                            )
                        }}
                    />
                    <TextField
                        label="Owner of Links"
                        fullWidth
                        variant="outlined"
                        value={localLink.coachEmail || ''}
                        onChange={(e) => handleChange(e, 'coachEmail')}
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => handleClear('coachEmail')} edge="end">
                                    <CloseIcon style={{ color: 'red' }} />
                                </IconButton>
                            )
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} style={{ color: "red" }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant='contained'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    useEffect(() => {
        fetchLinks();
        fetchSchools();
    }, []);

    useEffect(() => {
        fetchLinks();
        console.log('Hit')
    }, [selectedSchool]);

    // const handleAdd = (ageGroup) => {
    //     console.log('AgeGroup Received: ', ageGroup)
    //     // Find the corresponding data for the ageGroup
    //     const selectedData = schoolLinks?.find(data => data?.ageGroup === ageGroup);
    //     setSchoolLinks({
    //         ageGroup: selectedData?.ageGroup,
    //         googleFormLink: selectedData?.googleFormLink,
    //         googleSheetLink: selectedData?.googleSheetLink
    //     });
    //     setConfirmationModalOpen(true);
    // };



    // const handleAdd = (ageGroup) => {
    //     console.log('AgeGroup Received: ', ageGroup)
    //     const selectedData = schoolLinks?.find(data => data?.ageGroup === ageGroup);
    //     setSelectedLink(selectedData);  // Store only the selected link
    //     setSelectedSchoolLinks({
    //                 ageGroup: selectedData?.ageGroup,
    //                 googleFormLink: selectedData?.googleFormLink,
    //                 googleSheetLink: selectedData?.googleSheetLink,
    //                 coachEmail: selectedData?.coachEmail
    //             });
    //     setConfirmationModalOpen(true);
    // };

    // const handleChange = (e, field) => {
    //     setSelectedLink({
    //         ...schoolLinks,
    //         [field]: e.target.value
    //     });
    // };

    // const handleClear = (field) => {
    //     setSelectedLink({
    //         ...schoolLinks,
    //         [field]: ''
    //     });
    // };

    // const handleSave = () => {
    //     // Handle saving the updated Google Form and Google Sheet links
    //     // You can update the data in `schoolLinks` or send a request to your server
    //     console.log('Saving Data:', schoolLinks);
    //     setConfirmationModalOpen(false);
    // }

    // const handleAdd = (ageGroup) => {
    //     console.log('AgeGroup Received: ', ageGroup);
    //     const selectedData = schoolLinks?.find(data => data?.ageGroup === ageGroup);

    //     if (selectedData) {
    //         setSelectedLink({ ...selectedData });
    //         setConfirmationModalOpen(true);
    //     }
    // };

    // const handleChange = (e, field) => {
    //     setSelectedLink((prev) => ({
    //         ...prev,
    //         [field]: e.target.value
    //     }));
    // };

    // const handleClear = (field) => {
    //     setSelectedLink((prev) => ({
    //         ...prev,
    //         [field]: ''
    //     }));
    // };

    // const handleSave = () => {
    //     // Ensure selectedLink is updated within schoolLinks array
    //     setSchoolLinks((prev) =>
    //         prev.map((link) =>
    //             link.ageGroup === selectedLink.ageGroup ? selectedLink : link
    //         )
    //     );

    //     console.log('Saving Data:', selectedLink);
    //     setConfirmationModalOpen(false);
    // };

    const handleAdd = (ageGroup) => {
        const selectedData = schoolLinks?.find(data => data?.ageGroup === ageGroup);
        setSelectedLink(selectedData);
        setConfirmationModalOpen(true);
    };

    const handleSave = async (updatedLink) => {
        // console.log('Updated link: ', updatedLink)
        setSchoolLinks((prev) =>
            prev.map((link) =>
                link.ageGroup === updatedLink.ageGroup ? updatedLink : link
            )
        );
        setIsLoading(true)
        try {
            // Ensure the correct payload format
            const payload = {
                schoolId: schoolIWantId,
                ageGroup: updatedLink.ageGroup,
                googleFormLink: updatedLink.googleFormLink,
                googleSheetLink: updatedLink.googleSheetLink,
                coachEmail: updatedLink.coachEmail
            };

            console.log('PAyload sending: ', payload)
            const response = await devRequests.assignLinksAndEmail(schoolIWantId, payload);

            if (response.status === 200) {
                console.log('Success');
                setError(false)
                setMessage("Updated")
                setOpenSnackbar(true);

            } else {
                console.error('API Error: ', response);
                setMessage("Updated")
                setError(true)
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Request failed:', error);
            setMessage("Updated")
            setError(true)
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }

    };


    const fetchSchools = async () => {
        setisSchoolLoading(true);
        try {
            const response = await devRequests.getSchoolList();
            setisSchoolLoading(false);

            if (response.status === 200) {
                setReceivedSchools(response.data);
            } else {
                setMessage('Something went wrong');
                setError(true);
            }
        } catch (error) {
            console.error('Error fetching schools:', error);
        } finally {
            setisSchoolLoading(false);
        }
    };

    const fetchLinks = async () => {
        setIsLoading(true);
        const myLinks = [];

        try {
            const schoolIwant = receivedSchools.find((school) => school.name === selectedSchool);
            // console.log('SchoolIWant: ', schoolIwant)
            if (schoolIwant) {
                setSchoolIWantId(schoolIwant?.id)
                const response = await devRequests.getAllSchoolLinks(schoolIwant?.id);
                // console.log('All Links: ', response.data)
                setIsLoading(false);


                if (response.status === 200) {

                    // setSchoolLinks(response.data)
                    setSchoolLinks(Array.isArray(response.data) ? response.data : []);

                    // setAllCoaches(myCoaches);
                } else {
                    setMessage('Something went wrong');
                    setError(true);
                }
            } else {
                // No school selected
                setSchoolLinks([]);
            }
        } catch (error) {
            console.error('Error fetching links:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    const handleDeleted = (coachName) => {
        setHandleDeletedClicked(true);
        setCoachToBeDeleted(coachName);
        handleOpen();
    };

    const handleYesClick = async () => {
        setIsLoading(true);
        try {
            const coachIwant = allCoaches.find((coach) => coach.name === coachToBeDeleted);

            if (coachIwant) {
                const coachIDIwant = coachIwant.id; // Add your logic for getting the coach ID
                console.log('Coach I want ID: ', coachIDIwant);

                // Perform the API call to delete the coach
                const response = await devRequests.deleteCoach(coachIDIwant);
                console.log('Response from delete: ', response);

                fetchLinks();
                if (response.status === 200) {
                    setOpenSnackbar(true);
                    setMessage('Coach error');
                } else {
                    setOpenSnackbar(true);
                    setMessage('Something went wrong');
                }
            } else {
                setMessage('Selected coach not found');
                setError(true);
            }
        } catch (error) {
            console.error('Error deleting coach:', error);
        } finally {
            setHandleDeletedClicked(false);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h4>Filter by School</h4>
            {
                isSchoolLoading ?
                    <Loader />
                    :
                    <Select
                        id="demo-simple-select"
                        placeholder="Select School"
                        sx={{ width: '250px', height: '80%', }}
                        value={selectedSchool}
                        onChange={(e) => setSelectedSchool(e.target.value)}
                    >
                        {receivedSchools.map((school) => (
                            <MenuItem key={school.name} value={school.name}>
                                {school.name}
                            </MenuItem>
                        ))}
                    </Select>
            }

            <br />
            <br />

            <TableContainer component={Paper}>
                <h3 style={{ marginTop: '10px', marginBottom: '10px', textDecoration: 'underline' }}>Teams and Links</h3>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Age Group</TableCell>
                            <TableCell >Google Form link</TableCell>
                            <TableCell >Google Sheet link</TableCell>
                            <TableCell >Owner of link</TableCell>
                            <TableCell align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {schoolLinks?.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    {selectedSchool ? 'No links available' : 'No school has been selected'}
                                </TableCell>
                                {/* <TableCell colSpan={4} align="center">
                                    <Button onClick={() => handleAdd(0)} variant="text">
                                        Add
                                    </Button>
                                </TableCell> */}
                            </TableRow>
                        ) : (
                            schoolLinks?.map((data) => (
                                <TableRow key={data.ageGroup}>
                                    <TableCell>{data.ageGroup}</TableCell>
                                    <TableCell>
                                        <a href={data.googleFormLink} target="_blank" rel="noopener noreferrer">
                                            {data?.googleFormLink ? data.googleFormLink : "No link"}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <a href={data.googleSheetLink} target="_blank" rel="noopener noreferrer">
                                            {data.googleSheetLink ? data.googleSheetLink : "No Link"}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <a href={data.coachEmail} target="_blank" rel="noopener noreferrer">
                                            {data.coachEmail ? data.coachEmail : "No Enmail"}
                                        </a>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => handleAdd(data.ageGroup)} variant="text">
                                            Edit
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoading ? <Loader /> : <></>}
            <br />

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={error ? 'error' : 'success'}
                >
                    {Message}
                </MuiAlert>
            </Snackbar>

            {confirmationModalOpen && (
                <ConfirmationModal
                    open={confirmationModalOpen}
                    onClose={() => setConfirmationModalOpen(false)}
                    selectedLink={selectedLink}
                    onSave={handleSave}
                />

            )}



        </div>
    );
};

export default AdminTeams;
