export const schoolNames = [
    "Affies",
    "Alberton",
    "Ben Vorster",
    'Belville',
    'Bergsig',
    "Bishops",
    "Boland Landbou",
    "Benoni High",
    'Bredasdorp',
    "Bryanston",
    "Brandwag",
    "Centurion",
    "CBC Boksburg",
    "Cornwall Hill",
    "Charlie Hofmeyr",
    "Daniel Pienaar",
    "DF Malan",
    "Diamantveld",
    'Die Anker',
    'Dinamika',
    "DHS",
    "Dr. EG Jansen",
    "Durban High School",
    "Durbanville",
    "Eldoraigne",
    "Ermelo",
    "Empangeni",
    "Eshowe",
    "Fourways",
    "Framesby",
    "Frikkie Meyer",
    "Garsfontein",
    "Glenwood",
    "Germiston",
    "Greytown",
    "Graeme College",
    "Grey College",
    "Grey High School",
    "Hoër Volkskool Heidelberg",
    'Hoër Volkskool Potchefstroom',
    "Hermanus",
    "Hugenote",
    "Hugenote Wellington",
    "Helpmekaar",
    "Hilton College",
    "HTS Drostdy",
    "Hts John Vorster",
    "Hts Middelburg",
    "Jeppe",
    "John Ross",
    "Kearsney College",
    "Kempton Park",
    "KES",
    "Kingswood College",
    "King David",
    "Langenhoven",
    'Lichtenburg',

    "Marais Viljoen",
    "Marlow",
    "Maritzburg College",
    "Menlo Park",
    "Merensky",
    "Michaelhouse",
    "Middelburg HS",
    "Milnerton",
    "Monument",
    'Montana',
    "Montagu",
    "Nelspruit",
    "Nico Malan",
    "Noordheuwel",
    "Northwood",
    "Northcliff",
    "Oakdale",
    "Oos Moot",
    "Outeniqua",
    "Paarl Boys High",
    "Paarl Gimnasium",
    "Paul Roos Gym",
    "Pearson",
    "Piet Retief",
    "Pietersburg",
    'Potchefstroom Gimnasium',
    "Potch Boys",
    "Pretoria Boys High",
    "Pionier",
    "Punt",
    "Queens College",
    "Richardsbaai",
    "Riverwood",
    "Rondebosch",
    "Rustenburg",
    "SACS",
    "Selborne College",
    'Secunda',
    "Springs Boys",
    "St Albans College",
    "St Andrews College",
    "St Benedict's",
    "St Charles College",
    "St David's",
    "St Dunstan's",
    "St Johns College",
    "St Peter's",
    "St Stithians",
    "Stellenberg",
    "Strand",
    "Swartland",
    "Sutherland",
    "Transvalia",
    "Voortrekker Beth",
    "Vryheid",
    "Uplands",
    "Waterkloof",
    "Welkom Gim",
    "Westville",
    'Witteberg',
    "Wonderboom",
    "Worcester Gym",
    "Wynberg Boys' High",
    "Zwartkop",


]


















