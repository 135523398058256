import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';


// Components
import Container from './newContainer';
import Items from './newItem';
// import Modal from './newModal';
import Input from './newInput';
import { MyButton } from './newButton';
import Loader from '../../../layouts/loader';
import { teamRequests, userRequests } from '../../../services/api-requests';

import { OutTable, ExcelRenderer } from 'react-excel-renderer';

import { Button, TextField, FormGroup, useStepContext, MenuItem, Select, Typography, useMediaQuery, Divider } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setStatePlayers } from './teamSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import { Box, List, ListItem, ListItemText } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
};


function UploadTeams({ onUploadComplete }) {

    const dispatch = useDispatch()
    const [uploadType, setUploadType] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const handleOpen = () => setConfirmationModalOpen(true);
    const handleClose = () => setConfirmationModalOpen(false);
    const [openConfirmationSnackbar, setOpenConfirmationSnackbar] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('')
    const [error, setError] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);
    const [rows, setRows] = useState(null);
    const [cols, setCols] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [myAmountOfTeams, setmyAmountOfTeams] = useState([]);
    const [manuallyAddedPlayers, setManuallyAddedPlayers] = useState([])
    const [uploadSelected, setUploadSelected] = useState(false);
    const ageGroup = useSelector((state) => parseInt(state.user.profile.ageGroup))
    const schoolId = useSelector((state) => parseInt(state.user.profile.school))
    const [isLoading, setIsLoading] = useState(false);
    const [formLink, setFormLink] = useState("")
    const [sheetLink, setSheetLink] = useState("")
    const [email, setEmail] = useState("")
    const [positions, setPositions] = useState([
        { id: 1, name: 'Loose Head Prop (1)' },
        { id: 2, name: 'Hooker (2)' },
        { id: 3, name: 'Tight Head Prop (3)' },
        { id: 4, name: 'Lock (4)' },
        { id: 5, name: 'Lock (5)' },
        { id: 6, name: 'Openside Flanker (6)' },
        { id: 7, name: 'Blindside Flanker (7)' },
        { id: 8, name: 'Eight Man (8)' },
        { id: 9, name: 'Scrumhalf (9)' },
        { id: 10, name: 'Flyhalf (10)' },
        { id: 11, name: 'Left Wing (11)' },
        { id: 12, name: 'Inside Center (12)' },
        { id: 13, name: 'Outside Center (13)' },
        { id: 14, name: 'Right Wing (14)' },
        { id: 15, name: 'Fullback (15)' },
    ]);

    const fileInput = useRef(null);

    // useEffect(() => {
    //     if (onUploadComplete && myAmountOfTeams.length !== 0) {
    //         onUploadComplete(myAmountOfTeams);
    //     }
    //     console.log('My Amount in useEffect: ', myAmountOfTeams)
    // }, [myAmountOfTeams])


    useEffect(() => {
        fetchLinks();
    }, [])

    const fetchLinks = async () => {
        setIsLoading(true)
        try {
            const response = await userRequests.getSheetLinks(ageGroup, schoolId)

            if (response.status === 200) {
                // console.log('data: ', response.data)
                setSheetLink(response?.data?.googleSheetLink)
                setFormLink(response?.data?.googleFormLink)
                setEmail(response?.data?.coachEmail)
            }
            else {
                setIsLoading(false)
                setSheetLink("")
                setFormLink("")
                setEmail("")
            }
        } catch (error) {
            setIsLoading(false)
            setSheetLink("")
            setFormLink("")
            setEmail("")
        } finally {
            setIsLoading(false)
        }
    }

    function handleBack() {
        setUploadSelected(false);
        setUploadType('');
    }

    const ConfirmationModal = ({ open, onClose, googleFormLink }) => {
        const isMobile = useMediaQuery("(max-width:600px)")
        const handleNo = () => {
            onClose();
        };

        return (
            <div>
                <Modal open={open} onClose={onClose} aria-labelledby="upload-guide-title">
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "90vw" : "500px", // Responsive width
                        maxWidth: "500px", // Prevents too wide modals
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "10px",
                        p: isMobile ? 3 : 4, // Adjust padding based on screen size
                    }}>
                        <Typography id="upload-guide-title" variant="h5" fontWeight="bold" gutterBottom>
                            How to Upload Players
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            Follow these steps to upload your players:
                        </Typography>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="📩 Step 1: Share the Form"
                                    secondary={
                                        <>
                                            Send this Google Form link to your players:{" "}
                                            <a
                                                href={googleFormLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none", color: "#1976d2", fontWeight: 500 }}
                                            >
                                                {googleFormLink ? googleFormLink : "No link received yet"}
                                            </a>
                                        </>
                                    }
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="📧 Step 2: Check Your Email"

                                    secondary={
                                        <>
                                            You have access to both the form and its linked Google Sheet. Check your email for the access links. Access has been granted to: {" "}
                                            <a
                                                href={email}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none", color: "#1976d2", fontWeight: 500 }}
                                            >
                                                {email ? email : "No Email received yet"}
                                            </a>
                                        </>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="📥 Step 3: Download the Sheet"
                                    secondary={
                                        <>
                                            Open the Google Sheet and download it as an
                                            <Typography component="span" fontWeight="bold"> XLSX file (Excel format).</Typography>
                                            <br />
                                            The sheet can be found at: {" "}
                                            <a
                                                href={sheetLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none", color: "#1976d2", fontWeight: 500 }}
                                            >
                                                {sheetLink ? sheetLink : "No link received yet"}
                                            </a>
                                            <br />
                                            <br />
                                            <Typography component="span" fontWeight="bold">To download:</Typography>
                                            <br />
                                            1️⃣ Click <Typography component="span" fontWeight="bold">File</Typography> in the top menu.
                                            <br />
                                            2️⃣ Select <Typography component="span" fontWeight="bold">Download</Typography>.
                                            <br />
                                            3️⃣ Choose <Typography component="span" fontWeight="bold">Microsoft Excel (.xlsx)</Typography>.
                                        </>
                                    }
                                />
                            </ListItem>



                            <ListItem>
                                <ListItemText
                                    primary="📤 Step 4: Upload the File"
                                    secondary="Once you've downloaded the file, click the 'Browse' button below to upload it to our system."
                                />
                            </ListItem>
                        </List>

                        <Button
                            onClick={handleNo}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Got it
                        </Button>
                    </Box>
                </Modal>
            </div>
        );
    };



    const renderFile = (fileObj) => {
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                setDataLoaded(true);
                const fileCols = resp.cols.slice(1); // Remove the first column
                const fileRows = resp.rows // Remove the first row

                setCols(fileCols);
                setRows(fileRows);

                // console.log('Cols: ', fileCols);
                // console.log('Rows: ', fileRows);
            }
        });
    };


    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            if (onUploadComplete) {
                onUploadComplete(myAmountOfTeams);
            }
        }
        if (onUploadComplete) {
            onUploadComplete(myAmountOfTeams);
        }
        setOpenConfirmationSnackbar(false)
    }


    const fileHandler = (event) => {
        if (event.target.files.length) {
            //If file is 0, look at state, if state is 0 - no file has been added.
            let fileObj
            let fileName

            fileObj = event.target.files[0];
            // console.log('FileObj: ', fileObj)
            fileName = fileObj.name;

            if (fileName.slice(fileName.lastIndexOf('.') + 1) === 'xlsx') {
                setUploadedFileName(fileName);
                setIsFormInvalid(false);
                renderFile(fileObj);
            } else {
                setIsFormInvalid(true);
                setUploadedFileName('');
            }
        }
    };

    const openFileBrowser = () => {
        fileInput.current.click();
    };

    // function AmountOfTeams() {

    //     const result = Math.floor(rows.length / 15)
    //     console.log('result: ', result)
    //     return result
    // }

    // function AmountOfTeams() {
    //     const teamNames = 'ABCDEFGH';
    //     const result = Math.floor(rows.length / 15)

    //     if (result >= 0 && result < teamNames.length) {
    //         const teams = Array.from({ length: result + 1 }, (_, index) => `${teamNames[index]} Team`);
    //         console.log('Teams: ', teams);
    //         setmyAmountOfTeams(teams);
    //     } else {
    //         console.log('Invalid result');
    //         setmyAmountOfTeams([]);
    //     }
    // }



    //This returns the ids of all the positions
    const handlePositions = (positionList) => {
        const positionsArray = positionList.split(',').map((positionName) => {
            const trimmedPosition = positionName.trim();
            const position = positions.find((position) => position.name === trimmedPosition);
            return position ? position.id : null;
        });

        return positionsArray.filter((id) => id !== null);
    };

    // async function FileUpload(event) {
    //     setSelectedFile(event.target.value);
    //     console.log('Selected file', selectedFile);
    //     // Details of the uploaded file

    //     // Create an array to store player objects
    //     const playerArray = [];

    //     // Loop through the rows and construct player objects
    //     for (let i = 1; i < rows.length; i++) {
    //         const firstName = rows[i][1];
    //         const lastName = rows[i][2];
    //         const positionList = rows[i][3];
    //         const primarySchool = rows[i][4];

    //         //This returns the ids of all the positions
    //         const positionsArray = handlePositions(positionList);


    //         if (firstName && lastName && positionList) {
    //             const player = {
    //                 firstName: firstName,
    //                 lastName: lastName,
    //                 weight: 0,
    //                 height: 0,
    //                 positionList: positionsArray

    //             };
    //             playerArray.push(player);

    //         }
    //     }

    //     console.log('Final Array before dispatch: ', playerArray);

    //     try {
    //         setIsLoading(true);
    //         const response = await teamRequests.addPlayers(schoolId, ageGroup, playerArray);

    //         if (response.status === 200) {
    //             console.log('Success: ', response.data);
    //             setConfirmationMessage('Players Uploaded');
    //             setOpenConfirmationSnackbar(true);
    //             dispatch(setStatePlayers(playerArray));
    //             await AmountOfTeams(); // Await the function to complete
    //             console.log('My amount: ', myAmountOfTeams);
    //         } else {
    //             console.log('Nope: ', response.data);
    //         }
    //     } catch (error) {
    //         console.log('Error: ', error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }


    function excelSerialToJSDate(serial) {
        var utc_days = Math.floor(serial - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);

        var year = date_info.getFullYear();
        var month = (date_info.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        var day = date_info.getDate().toString().padStart(2, '0');

        // Ensure the time is set to 00:00:00
        var formattedDate = `${year}-${month}-${day}T00:00:00.000Z`;
        // Ensure the time is set to 00:00:00
        var justDate = `${year}-${month}-${day}`;

        return justDate;
    }





    async function FileUpload(event) {

        console.log('rows', rows)
        if (rows === null || rows.length <= 2) {
            setError(true)
            setConfirmationMessage("Please select an XLSX file. Or ensure you have more than 1 player in your list")
            setOpenConfirmationSnackbar(true)
            return;
        }


        setSelectedFile(event.target.value);
        // console.log('Selected file', selectedFile);

        // Create an array to store player objects
        const playerArray = [];

        // Loop through the rows and construct player objects
        for (let i = 1; i < rows.length; i++) {
            const firstName = rows[i][1];
            const lastName = rows[i][2];
            const positionList = rows[i][3];
            const dateOfBirth = excelSerialToJSDate(rows[i][4])

            // Validate and convert positions to IDs
            const positionsArray = handlePositions(positionList);

            // Check if required fields are present
            if (firstName && lastName && positionsArray.length > 0) {
                const player = {
                    firstName: firstName,
                    lastName: lastName,
                    weight: 0,
                    height: 0,
                    positionList: positionsArray,
                    dateOfBirth: dateOfBirth,
                };
                playerArray.push(player);
            }
        }

        // console.log('Final Array before dispatch: ', playerArray);

        try {
            setIsLoading(true);
            const response = await teamRequests.addPlayers(schoolId, ageGroup, playerArray);

            if (response.status === 200) {
                // console.log('Success: ', response.data);
                setConfirmationMessage('Players Uploaded');
                setOpenConfirmationSnackbar(true);
                setError(false)
                dispatch(setStatePlayers(playerArray));
                await AmountOfTeams(); // Await the function to complete
                // console.log('My amount: ', myAmountOfTeams);
            } else {
                setError(true)
                setConfirmationMessage('Could not Upload players');
                setOpenConfirmationSnackbar(true);
                console.error('Error: ', response.status);
            }
        } catch (error) {
            setError(true)
            setConfirmationMessage('Could not Upload players');
            setOpenConfirmationSnackbar(true);
            console.error('Error: ', error);
        } finally {
            setIsLoading(false);
        }
    }


    async function AmountOfTeams() {
        const teamNames = 'ABCDEFGH';
        const result = Math.floor(rows.length / 15);

        let teams
        if (result >= 0 && result < teamNames.length) {
            teams = Array.from({ length: result + 1 }, (_, index) => `${teamNames[index]} Team`);
            // console.log('Teams: ', teams);
            setmyAmountOfTeams(teams);
        } else {
            console.error('Invalid result');
            setmyAmountOfTeams([]);
        }

        if (onUploadComplete && teams.length !== 0) {
            // onUploadComplete(myAmountOfTeams);
            onUploadComplete(teams);
        }
        // console.log('My Amount in function: ', teams);
    }




    return (
        <>
            {
                isLoading ?
                    <Loader />
                    :
                    <div style={{ height: "100%", marginTop: '-10px' }}>

                        <div style={{ textAlign: "center" }}>
                            <Typography variant="h4" gutterBottom style={{ textDecoration: 'underline' }}>
                                Upload Teams
                            </Typography>

                            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
                                Here is your Google Form link:
                            </Typography>

                            <a
                                href={formLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "underline", color: "inherit", fontSize: "15px", fontWeight: 300 }}
                            >
                                {formLink ? formLink : "No link received yet"}
                            </a>


                            <br />
                            <Typography variant="body1" style={{ marginTop: "10px", fontWeight: 300 }}>
                                Send the above link to your players
                            </Typography>
                            <br />


                            {/* Centered Dividers */}
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "20px 0" }}>
                                <Divider style={{ width: "60%" }} />
                                <Divider style={{ width: "60%" }} />
                            </div>



                            <Typography variant="h6" gutterBottom>
                                Here is your Google Sheets link:
                            </Typography>

                            <a
                                href={sheetLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "underline", color: "inherit", fontSize: "15px", fontWeight: 500 }}
                            >
                                {sheetLink ? sheetLink : "No link received yet"}
                            </a>


                            <br />
                            <Typography variant="body1" style={{ marginTop: "10px", fontWeight: 300 }}>
                                Use this link to download the responses from the players in XLSX format.
                            </Typography>

                            <Button style={{ marginTop: '20px' }} variant='outlined' onClick={() => { setConfirmationModalOpen(true) }}> <HelpIcon />How do i upload players?</Button>
                        </div>


                        <div style={{ marginTop: "50px", }}>
                            <form >
                                <FormGroup style={{ display: 'flex', flexDirection: 'row', }}>


                                    <Button
                                        variant="contained"
                                        color="info"
                                        onClick={openFileBrowser}
                                        className={"button"}
                                        style={{ width: '100px' }}

                                    >
                                        <i className="cui-file"></i> Browse…
                                    </Button>

                                    <input
                                        type="file"
                                        hidden
                                        onChange={fileHandler}
                                        ref={fileInput}
                                        onClick={(event) => {
                                            event.target.value = null;
                                        }}
                                        style={{ padding: '10px', width: '80%' }}
                                    />

                                    <TextField
                                        type="text"
                                        value={uploadedFileName}
                                        readOnly
                                        error={isFormInvalid}
                                        helperText={
                                            isFormInvalid && 'Please select a .xlsx file only!'
                                        }
                                    />
                                    <br />
                                </FormGroup>
                                <br />
                            </form>
                            {dataLoaded && (
                                <Paper variant="outlined"
                                    className={"paper"} style={{ height: '500px', overflowX: 'auto', overflowY: 'auto' }} >
                                    <Table
                                        className="table"
                                        style={{ height: '500px' }}
                                    >
                                        <TableHead>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => (
                                                <TableRow key={index}>
                                                    {row.slice(1).map((cell, cellIndex) => (
                                                        <TableCell key={cellIndex} className={"tableCell"}>
                                                            {cell}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </Paper>
                            )}
                        </div>

                        <br />
                        <Button variant='contained' onClick={FileUpload}>Submit All Players</Button>
                        <br />
                        <Button onClick={() => { handleBack() }}>Go back</Button>

                        <Snackbar
                            open={openConfirmationSnackbar}
                            autoHideDuration={4000}
                            onClose={handleCloseConfirmationSnackbar}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={handleCloseConfirmationSnackbar}
                                severity={error ? "error" : "success"}
                            >
                                {confirmationMessage}
                            </MuiAlert>
                        </Snackbar>


                        {confirmationModalOpen && (
                            <ConfirmationModal open={confirmationModalOpen} onClose={handleClose} googleFormLink={formLink} sheetLink={sheetLink} />
                        )}

                    </div>
            }
        </>
    )
}

export default UploadTeams
