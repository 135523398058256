import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    Snackbar
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';

import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../layouts/loader";
import { playerProgramRequests } from "../../services/api-requests";

const ViewPlayerProgram = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [program, setProgram] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');

    useEffect(() => {
        fetchProgram();
    }, []);

    const fetchProgram = async () => {
        console.log('First number: ', id)
        const correctProgramId = Number(id)
        console.log('New number: ', correctProgramId)
        setIsLoading(true);
        try {
            const response = await playerProgramRequests.GetProgramById(correctProgramId);
            if (response.status === 200) {
                setProgram(response.data);
            } else {
                setError(true);
                setConfirmationMessage('Failed to fetch program');
                setOpenSnackbar(true);
            }
        } catch (err) {
            console.error('Fetch Error:', err);
            setError(true);
            setConfirmationMessage('Error fetching program');
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ p: 3, maxWidth: 600, mx: "auto" }}>
            {isLoading ? <Loader /> : (
                <>
                    <Typography variant="h5" gutterBottom>View Player Program</Typography>
                    {program ? (
                        <>
                            <Typography variant="subtitle1">
                                Opponent: {program.opponent}
                            </Typography>
                            <Typography variant="subtitle1">
                                Game Date: {new Date(program.gameDate).toLocaleDateString()}
                            </Typography>

                            <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {program.actions?.map((action, index) => (
                                    <ListItem key={index} sx={{ width: '100%', justifyContent: 'center' }}>
                                        <ListItemText
                                            primary={`Time: ${action.timestamp}`}
                                            secondary={`${action.action}`}
                                            sx={{ textAlign: 'center' }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        <Typography>No Program Found</Typography>
                    )}

                    <Button variant="outlined" onClick={() => navigate(-1)} fullWidth>
                        Back
                    </Button>

                </>
            )}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={error ? "error" : "success"}>
                    {confirmationMessage}
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default ViewPlayerProgram;
