import * as React from 'react';
import { useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { team } from './team';
import { pushActions, setOpponent, updateTryScorer } from './gamedaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './actions';
import { Divider } from '@mui/material';
import { useState } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';



export default function HalfTimeReportDialog(props) {
    console.log('Props in dialog: ', props)
    const [open, setOpen] = useState(true);
    const [fullWidth, setFullWidth] = useState(true);
    const [tryScorer, setTryScorer] = useState();
    const dispatch = useDispatch();
    const [actionCategory, setCategory] = useState('');
    const [actionEvent, setActionEvent] = useState('');
    const [addEvent, setAddEvent] = useState(false);
    const [reportError, setReportError] = useState(false);

    console.log('Props received in HalfTimeReportDialog component: ', props)
    // const startingLineUp = props.team.team.players
    // const bench = props.team.team.reservePlayers

    // console.log('Starting lineup is: ', startingLineUp)
    // console.log('Bench lineup is: ', bench)


    const handleClose = () => {
        // if (!tryScorer) {
        //     alert("Select the try scorer")
        // }
        // else {
        setOpen(false);
        // }
        // Add any additional logic or alerts here if needed
    };


    const handeSubmit = () => {

        setAddEvent(false)
        console.log('In the handle submit event fuction')
        console.log('Category: ', actionCategory)
        console.log('Description: ', actionEvent)

        const newReportEvent = [...props.finalReportArray, { "category": actionCategory, "description": actionEvent }]

        setOpen(false);

        //Handle the object created in the parent elements
        props.EventSubmitted(newReportEvent)
    }


    return (
        <React.Fragment>
            <Dialog
                fullWidth={false}
                widt='400px'
                maxWidth='400px'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add Event</DialogTitle>
                <DialogContent>

                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >
                        <FormControl sx={{ mt: 2, minWidth: 120 }}>

                            <InputLabel htmlFor="category">Category</InputLabel>
                            <Select
                                labelId="category"
                                id="category"
                                value={actionCategory}
                                label="Action Category"
                                onChange={(event) => {
                                    setCategory(event.target.value)
                                    setReportError(false)
                                }}
                                sx={{ width: '250px', marginBottom: '15px' }}
                                error={reportError} //Add error prop
                                helperText={reportError ? 'Please complete this field' : ''}
                            >
                                <MenuItem value={"Attack"}>Attack</MenuItem>
                                <MenuItem value={"Defence"}>Defence</MenuItem>
                                <MenuItem value={"Lineouts"}>Lineouts</MenuItem>
                                <MenuItem value={"Scrums"}>Scrums</MenuItem>
                                <MenuItem value={"Kick off receive"}>Kick off receive</MenuItem>
                                <MenuItem value={"Kick off"}>Kick off</MenuItem>
                                <MenuItem value={"Kicks"}>Kicks</MenuItem>
                                <MenuItem value={"Rucks"}>Rucks</MenuItem>
                            </Select>

                            <p>Action:</p>
                            <TextField
                                value={actionEvent}
                                onChange={(e) => setActionEvent(e.target.value)}
                                name="eventDescription"
                                required
                                sx={{ width: '250px', marginBottom: '20px', marginTop: '5px' }}
                                id="eventDescription"
                                label="Event description"
                            >

                            </TextField>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <Button onClick={handleClose} style={{ color: 'red' }}>Close</Button>
                    <Button variant="contained" type='submit' onClick={handeSubmit}> <SaveAltIcon style={{ marginRight: '2px' }} /> Submit Event</Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
