import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { setActiveTeam, setUserLoggedIn } from '../gameday/gamedaySlice';
import { useDispatch } from 'react-redux';
import { setActiveStatGroup } from '../stats/statsSlice';
import { setActiveSeason } from '../season/seasonSlice';
import Popup from '../../components/ReportPopup';
import { useSelector } from 'react-redux';
import { setActiveTabTeam } from '../session/sessionSlice';
import { setActiveTeamTab } from '../team/newTeam/teamSlice';
import { useNavigate } from 'react-router-dom';
import BugReportIcon from '@mui/icons-material/BugReport';
import { setGymActiveTabTeam } from '../gym/gymSessionSlice';


const lightColor = 'rgba(255, 255, 255, 0.7)';


function Header(props) {
  const dispatch = useDispatch()
  const { onDrawerToggle } = props;
  const [activePage, setActivePage] = useState('');
  // const [availableTeams, setAvailableTeams] = useState('');
  const [activeOptions, setActiveOptions] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [activeIndex, setActiveIndex] = useState(0)
  const [isBugReportOpen, setIsBugReportOpen] = useState(false);
  const availableTeams = useSelector((state) => state.team.availableTeams)

  const schoolId = parseInt(useSelector((state) => state.user.profile.school));
  const userId = useSelector((state) => state.user.profile.id)
  const userFirstName = useSelector((state) => state.user.profile.FirstName)
  const lastName = useSelector((state) => state.user.profile.LastName)
  const fullName = `${userFirstName} ${lastName}`
  const firstLetterName = userFirstName[0]
  const firstLetterLastName = lastName[0]
  const role = useSelector((state) => state.user.profile.role ? state.user.profile.role : '')
  let manager

  role === 'Manager' ? manager = true : manager = false

  const navigate = useNavigate();
  // console.log('AvailableTeams in Header: ', availableTeams)

  useEffect(() => {
    const handleStorageChange = () => {
      const storedActivePage = sessionStorage.getItem('activePage');
      setActivePage(storedActivePage);
    };

    // Add event listener to listen for changes in sessionStorage
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const teamOptions = [
    'Selection',
    'Squad',
    'RTP Players',
    'Injured',
  ];


  const gameDayOptions = availableTeams.length > 0 ?
    availableTeams.map((team) => { return (team.name); })
    :
    []





  const statsOptions = [
    'Game Day Stats',
    'Training Stats',
    'Season Stats',
    'Team Stats',
    'Player Stats'
  ];
  const calendarOptions = [
    // 'Week',
    // 'Day',
    // 'Month',
    // 'Year'
  ];
  const sessionOptions = availableTeams.length > 0 ?
    availableTeams.map((team) => { return (team.name); })
    :
    []
  sessionOptions.unshift("All Teams");

  const gymSessionOptions = availableTeams.length > 0 ?
    availableTeams.map((team) => { return (team.name); })
    :
    []
  gymSessionOptions.unshift("All Teams");

  const settingOptions = [];



  const seasonOptions = availableTeams.length > 0 ?
    availableTeams.map((team) => { return (team.name); })
    :
    []

  const playerProgram = availableTeams.length > 0 ?
    availableTeams.map((team) => { return (team.name); })
    :
    []

  const ResourceOptions = [
    'Files',
    'Templates'
  ];

  const dashboardOptions = [
  ];

  const devOptions = [];


  useEffect(() => {
    //For coaches and users
    switch (activePage) {
      case 'Dashboard':
        setActiveOptions(dashboardOptions);
        break;
      case 'GameDay':
        setActiveOptions(gameDayOptions);
        break;
      case 'Teams':
        setActiveOptions(teamOptions);
        break;
      case 'Stats':
        setActiveOptions(statsOptions);
        break;
      case 'Sessions':
        setActiveOptions(sessionOptions);
        break;
      case 'Calendar':
        setActiveOptions(calendarOptions);
        break;
      case 'Settings':
        setActiveOptions(settingOptions);
        break;
      case 'Season':
        setActiveOptions(seasonOptions);
        break;
      case 'Resources':
        setActiveOptions(ResourceOptions);
        break;
      case 'Log Out':
        dispatch(setUserLoggedIn(false))
        break;

      //For Devs and admins
      case 'Admin Dashboard':
        setActiveOptions(devOptions);
        break;
      case 'Managers':
        setActiveOptions(devOptions);
        break;
      case 'Schools':
        setActiveOptions(devOptions);
        break;
      case 'Coaches':
        setActiveOptions(devOptions);
        break;
      case 'Players':
        setActiveOptions(devOptions);
        break;
      case 'Manage SC Team':
        setActiveOptions(devOptions);
        break;
      case 'Resources':
        setActiveOptions(ResourceOptions);
        break;
      case 'Developer Settings':
        setActiveOptions(devOptions);
        break;

      //For extra features
      case 'Player Program':
        setActiveOptions(playerProgram);
        break;
      case 'Gym Sessions':
        setActiveOptions(gymSessionOptions)
        break;
      default:
        setActiveOptions(dashboardOptions);
        break;
    }
  }, [activePage]);

  useEffect(() => {
    // Distinguish between which tab goes to which slice
    if (activePage === 'GameDay') {
      dispatch(setActiveTeam(activeTab));
    }
    if (activePage === 'Player Program') {
      dispatch(setActiveTeam(activeTab));
    }
    if (activePage === 'Stats') {
      dispatch(setActiveStatGroup(activeTab));
    }
    if (activePage === 'Season') {
      dispatch(setActiveSeason(activeTab));
    }
    if (activePage === 'Sessions') {
      dispatch(setActiveTabTeam(activeTab));
    }
    if (activePage === 'Gym Sessions') {
      dispatch(setGymActiveTabTeam(activeTab));
    }
    if (activePage === 'Teams') {
      dispatch(setActiveTeamTab(activeTab));
    }

  }, [activeTab]); // Add activeTab as a dependency here


  /* if any errors occur between the tabs,
     change or remove the below code.
     The effect above is original and working
  */

  //To ensure that the options always start at 0 when going to a new component
  useEffect(() => {

    if (activePage === 'GameDay') {
      tabClick(activeOptions[0], 0)
      dispatch(setActiveTeam(activeOptions[0])); // Set to the first available option
    }
    if (activePage === 'Player Program') {
      tabClick(activeOptions[0], 0)
      dispatch(setActiveTeam(activeOptions[0])); // Set to the first available option
    }
    if (activePage === 'Stats') {
      tabClick(activeOptions[0], 0)
      dispatch(setActiveStatGroup(activeOptions[0]));
    }
    if (activePage === 'Season') {
      tabClick(activeOptions[0], 0)
      dispatch(setActiveSeason(activeOptions[0]));
    }
    // if (activePage === 'Sessions') {
    //   dispatch(setActiveTabTeam(activeOptions[0])); 
    // }
    if (activePage === 'Teams') {
      tabClick(activeOptions[0], 0)
      dispatch(setActiveTeamTab(activeOptions[0]));
    }

  }, [activePage, activeOptions]);




  function tabClick(option, index) {
    setActiveTab(option)
    setActiveIndex(index)

  }


  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
            </Grid>
            <Grid item>
              <Tooltip title="Go to User Guides?" onClick={() => navigate('/Resource/UserGuides')} >
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={() => { !manager ? navigate(`/Settings/${schoolId}/${userId}`) : navigate(`/Settings`) }}>
                {/* <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" /> */}
                <Avatar >{firstLetterName}{firstLetterLastName}</Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1" alignItems="center">
                {activePage === 'GameDay' ? 'Game Day' : activePage}


              </Typography>
            </Grid>

            <Grid item>
              <Tooltip title="Report a Bug" onClick={() => setIsBugReportOpen(true)} >
                <IconButton color="inherit">
                  <BugReportIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Tabs value={activeIndex} textColor="inherit">

          {activeOptions.map((option, index) => (
            <Tab key={index} label={option} onClick={() => { tabClick(option, index) }} />
          )
          )}
        </Tabs>

      </AppBar>
      <Popup isOpen={isBugReportOpen} setBugReportWindowStatus={setIsBugReportOpen}></Popup>

    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;