import React, { useEffect, useState } from "react";
import {
    Box,
    MenuItem,
    Select,
    TextField,
    Button,
    IconButton,
    Typography,
    Snackbar,

} from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
import { playerProgramRequests, sessionRequests } from "../../services/api-requests";
import { useSelector } from "react-redux";
import Loader from "../../layouts/loader";
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";



const AddPlayerProgram = () => {
    const navigate = useNavigate();
    const [selectedGame, setSelectedGame] = useState("");
    const [actions, setActions] = useState([]);
    const schoolId = useSelector((state) => parseInt(state.user.profile.school))
    const activeTeam = useSelector((state) => state.gameday.activeTeam);
    const [isLoading, setIsLoading] = React.useState(false)

    const [error, setError] = React.useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState('');
    const availableTeams = useSelector((state) => state.team.availableTeams)
    const [previousPrograms, setPreviousPrograms] = React.useState([])
    const seasonGames = useSelector((state) => state?.season?.backendSeason?.games)
    console.log('Season games: ', seasonGames)
    const [teamId, setTeamId] = React.useState(0)


    useEffect(() => {
        const team = availableTeams.find((team) => team.name === activeTeam);

        if (team !== undefined) {
            setTeamId(team.id);
        }

    }, [activeTeam])


    const handleAddAction = () => {
        setActions([...actions, { Timestamp: "", Action: "" }]);
    };

    const handleDeleteAction = (index) => {
        setActions(actions.filter((_, i) => i !== index));
    };

    const handleChange = (index, field, value) => {
        const updatedActions = [...actions];
        updatedActions[index][field] = value;
        setActions(updatedActions);
    };


    const handleSubmit = async () => {
        if (actions.length < 1 || teamId === 0 || selectedGame.id === 0) {
            console.error('Missing information to create program')
            setError(true)
            setConfirmationMessage('Missing information to create program')
            setOpenSnackbar(true)
            setIsLoading(false)
        }
        else {
            try {
                setIsLoading(true)
                const programObject = {

                    TeamId: teamId,
                    GameId: selectedGame.id,
                    Opponent: selectedGame.opponent,
                    //  GameDate: new Date(selectedGame?.date).toLocaleDateString(),
                    GameDate: new Date(selectedGame?.date).toISOString().split('T')[0],
                    Actions: actions
                }
                console.log('Adding Program: ', programObject)

                const response = await playerProgramRequests.CreateNewProgram(programObject)
                // const response = await gameRequests.addGame(teamId, gameObject)


                if (response.status === 200) {

                    setError(false)
                    setConfirmationMessage('Program Created')
                    setOpenSnackbar(true)
                    setIsLoading(false)
                }
                else if (response.status === 404) {
                    console.error('Could not create program: ', response.data)
                    setError(true)
                    setConfirmationMessage('Could not create session. Missing Information')
                    setOpenSnackbar(true)
                    setIsLoading(false)
                }
                else {
                    console.error('Could not create program: ', response.data)
                    setError(true)
                    setConfirmationMessage('Could not create program. Something went wrong')
                    setOpenSnackbar(true)
                    setIsLoading(false)
                }
            } catch (error) {
                console.error('Could not create program: ', error)
                setError(true)
                setConfirmationMessage('Could not create program. Something went wrong')
                setOpenSnackbar(true)
                setIsLoading(false)
            } finally {
                setIsLoading(false)

            }
        }
    };

    const handleClose = (event, reason) => {

        setOpenSnackbar(false);
        navigate(-1)
    };


    return (
        <Box sx={{ p: 3, maxWidth: 600, mx: "auto" }}>
            {
                isLoading ?
                    <Loader />
                    :
                    <>
                        <Typography variant="h5" gutterBottom>
                            Player Program
                        </Typography>
                        <Select
                            value={selectedGame}
                            onChange={(e) => setSelectedGame(e.target.value)}
                            displayEmpty
                            fullWidth
                            sx={{ mb: 2 }}
                        >
                            <MenuItem value="" disabled>
                                Select a Game
                            </MenuItem>
                            {
                                seasonGames?.map((season) => {
                                    const mydate = new Date(season?.date).toLocaleDateString()
                                    return (

                                        <MenuItem key={season.id} value={season}>
                                            {season?.opponent ? season?.opponent : 'No opponent'} - {season?.date ? mydate : "No date"}
                                        </MenuItem>

                                    )
                                })
                            }
                        </Select>

                        {actions.map((item, index) => (
                            <Box key={index} sx={{ display: "flex", gap: 1, mb: 2, alignItems: "center" }}>
                                <TextField
                                    type="time"
                                    value={item.timestamp}
                                    onChange={(e) => handleChange(index, "Timestamp", e.target.value)}

                                    sx={{ width: 160 }} // Ensuring it's smaller
                                />
                                <TextField
                                    value={item.action}
                                    onChange={(e) => handleChange(index, "Action", e.target.value)}
                                    label="Action"
                                    sx={{ flexGrow: 1 }} // Makes it take the remaining space
                                />
                                <IconButton style={{ marginRight: '-15px' }} onClick={() => handleDeleteAction(index)} color="error">
                                    <Delete />
                                </IconButton>
                            </Box>

                        ))}

                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            onClick={handleAddAction}
                            fullWidth
                        >
                            Add Action
                        </Button>
                        <br />
                        <br />
                        <Button
                            variant="outlined"
                            onClick={() => { handleSubmit() }}
                            fullWidth
                        >
                            Submit Program
                        </Button>
                        <br />
                        <br />
                        <Button

                            onClick={() => { navigate(-1) }}
                            fullWidth
                        >
                            Back
                        </Button>

                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={handleClose}
                                severity={error ? "error" : "success"}
                            >
                                {confirmationMessage}

                            </MuiAlert>
                        </Snackbar>
                    </>
            }

        </Box>

        // <Typography>Coming soon...</Typography>
    );
};

export default AddPlayerProgram;
